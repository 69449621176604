import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import notificationMessage from '@/config/notificationMessage/notificationMessage';
//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';

const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};


const state = () => ({
  boardData: [],
  taskData: [],
  checklistData: [],
  checklistitemData: [],
  loading: false,
  tsLoading: false,
  tsreloading: false,
  error: null,
  data: [],
  success: false,
  resx: null,
  message: null,
});


const actions = {

  async taskGetDataAll({ commit }) {
    try {
      await commit('taskReadBegin');

      console.log('taskReadBegin');

      const query = await DataService.get('/tasks/all');
      console.log(query.data.data);
      await commit('taskReadSuccess', query.data.data);
    } catch (err) {
      console.log(err);
      await commit('taskReadErr', err);
    }
  },

  async taskSubmitData({ commit }, data) {   // ToAddTask

    action.action = "insert";
    action.oggetto = "task";

    await commit('taskAddBeginBegin');    
    try {
      const response = await DataService.post('/tasks/store', data);
      
      notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
      //await commit('taskAddBeginSuccess', response.data.data);
      await commit('taskAddSuccess', response.data.data);
      console.log("taskAddSuccess message: " + response.data.message);

      action.oggetto_json = data; //JSON.stringify()
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } catch (err) {
      //await commit('taskAddBeginErr', err);
      await commit('taskAddErr', err);
      console.log(err);
      notificationMessage.addNotificationError(err.message);
      
      action.evento = "error";      
      action.oggetto_json = err ;//JSON.stringify(err);
      action.descrizione = "errore inserimento dati";
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  async taskUpdateData({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "update";
    action.oggetto_json = data; //JSON.stringify(data);

    try {
      await commit('taskUpdateBegin');
      const response = await DataService.put(`/tasks/${id}/updatetd`, data);
      commit('taskUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('taskUpdateErr', err);
      if (typeof err === 'string')
        {    
          console.log("ok err=", err);  
          notificationMessage.updateNotificationError(error);
        }
        else {error = JSON.stringify(err);}      
      
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },
  
  async taskDeleteData({ commit }, id) { // { id, getData }
    try {
      await commit('taskDeleteBegin');
      const response = await DataService.delete(`/tasks/${id}/delete`, {});
      await commit('taskDeleteSuccess');
      //await getData();      
      notificationMessage.deleteNotificationSuccess(response.data.message);
    } catch (err) {

      let error = err;
      await commit('taskDeleteErr', err);
      if (typeof err === 'string')
        {    
          console.log("ok err=", err);  
          notificationMessage.deleteNotificationError(error);
        }
        else {error = JSON.stringify(err);}      
      
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));
    }
  },

  ////////////////////////////////////////////////////////////////
  // CHECK LIST
  async taskInsertCheckList({ commit }, { id, data }) {
    
    action.action = "insert";
    action.oggetto = "checklist";
    action.oggetto_json = data; //JSON.stringify(data);
    action.task = "task";
    action.task_id = id;
 

    try {
      await commit('taskUpdateBegin');
      const response = await DataService.put(`/tasks/${id}/insertchecklist`, data);
      commit('taskUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('taskUpdateErr', err);
      if (typeof err === 'string')
        {    
          console.log("ok err=", err);  
          notificationMessage.updateNotificationError(error);
        }
        else {error = JSON.stringify(err);}      
      
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  async taskDeleteCheckList({ commit }, { id, data }) {
    
    action.action = "delete";
    action.oggetto = "checklist";
    action.oggetto_json = data; //JSON.stringify(data);
    action.task = "checklist";
    action.task_id = id;  
 
    try {
      await commit('taskDeleteBegin');
      const response = await DataService.put(`/tasks/${id}/deletechecklist`, data);
      commit('taskDeleteSuccess');
      notificationMessage.deleteNotificationSuccess(response.data.message);
      
      action.task_id = response.data.data.id;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('taskDeleteErr', err);
      if (typeof err === 'string')
        {    
          console.log("ok err=", err);  
          notificationMessage.deleteNotificationError(error);
        }
        else {error = JSON.stringify(err);}      
      
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // CHECK LIST ITEM
  async taskInsertCheckListItem({ commit }, { id, data }) {
    
    action.action = "insert";
    action.oggetto = "checklistitem";
    action.oggetto_json = data; //JSON.stringify(data);
    action.task = "checklistitem";
    action.task_id = ""; // recuperato dopo l'ok dell'insert
 

    try {
      await commit('taskUpdateBegin');
      const response = await DataService.put(`/tasks/${id}/insertchecklistitem`, data);
      commit('taskUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      action.task_id = response.data.data.id;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('taskUpdateErr', err);
      if (typeof err === 'string')
        {    
          console.log("ok err=", err);  
          notificationMessage.updateNotificationError(error);
        }
        else {error = JSON.stringify(err);}      
      
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

      
    }
  },

  async taskDeleteCheckListItem({ commit }, { id, data }) {
    
    action.action = "delete";
    action.oggetto = "checklistitem";
    action.oggetto_json = data; //JSON.stringify(data);
    action.task = "checklistitem";
    action.task_id = id;  
 

    try {
      await commit('taskDeleteBegin');
      const response = await DataService.put(`/tasks/${id}/deletechecklistitem`, data);
      commit('taskDeleteSuccess');
      notificationMessage.deleteNotificationSuccess(response.data.message);
      
      action.task_id = response.data.data.id;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('taskDeleteErr', err);
      if (typeof err === 'string')
        {    
          console.log("ok err=", err);  
          notificationMessage.deleteNotificationError(error);
        }
        else {error = JSON.stringify(err);}      
      
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  async taskChangeCheckListItem({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "checklistitem";
    action.oggetto_json = data; //JSON.stringify(data);
    action.task = "checklistitem";
    action.task_id = id; // recuperato dopo l'ok dell'insert
    action.descrizione = "aggiornamento check obiettivo " + data.value
   

    try {
      await commit('taskUpdateBegin');
      const response = await DataService.put(`/tasks/${id}/changechecklistitem`, data);
      commit('taskUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      action.task_id = response.data.data.id;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('taskUpdateErr', err);
      if (typeof err === 'string')
        {    
          console.log("ok err=", err);  
          notificationMessage.updateNotificationError(error);
        }
        else {error = JSON.stringify(err);}      
      
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  async taskRender({ commit }) {
    try {
      commit('taskUpdateBegin');
      commit('taskUpdateSuccess');
    } catch (err) {
      commit('taskUpdateErr', err);
    }
  },



  ////////////////////////////////////////////////////////////////


  async taskAddData({ commit }, data) {
    try {
      commit('taskReadBegin');
      commit('taskReadSuccess', data);
    } catch (err) {
      commit('taskReadErr', err);
    }
  },

  async onTaskStarUpdate({ commit }, {data, id }) {

    try {
      commit('starUpdateBegin');

      data.map((item) => {
        if (item.id === id) {
          const fav = item;
          if (fav.favourite) {
            item.favourite = false;
          } else {
            fav.favourite = true;
          }
        }
      });

      return commit('starUpdateSuccess', data);
    } catch (err) {
      commit('starUpdateErr', err);
    }
  },

  async onCompleteUpdate({ commit }, {data, id }) {
    try{
      commit('completeUpdateBegin');
      data.map((item) => {
        if (item.id === id) {
          const complete = item;
          if (complete.completed) {
            item.completed = false;
          } else {
            complete.completed = true;
          }
        }
      });
      return commit('completeUpdateSuccess', data);
    } catch (err) {
      commit('completeUpdateErr', err);
    }
  },

  async ontaskDelete({ commit }, data) {
    try {
      commit('taskReadBegin');
      commit('taskReadSuccess', data);
    } catch (err) {
      commit('taskReadErr', err);
    }
  },

  async ontaskEdit({ commit }, data) {
    try {
      commit('taskReadBegin');
      commit('taskReadSuccess', data);
    } catch (err) {
      commit('taskReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  
};
