import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import notificationMessage from '@/config/notificationMessage/notificationMessage';
//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';

const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};

const state = () => ({
  data: [],
  loading: false,
  error: null,
  success: false,
  resx: null,
  message: null,
  // activities single contact
  dataContact: [],
  loadingContact: false,
  errorContact: null,
  successContact: false,
  resxContact: null,
  messageContact: null,

});

const actions = {

  async activitiesGetDataContact({ commit }, id) {
    try {
      await commit('activitiesReadBeginContact');
      const query = await DataService.get(`/activities/contact/${id}`);   
      let respx = [query.data.success,query.data.resx,query.data.message];
      const xresponse = respx.map(item => JSON.stringify(item))       
      await commit('activitiesReadSuccessContact', query.data.data);
      await commit('activitiesResponseContact', xresponse);
    } catch (err) {
      await commit('activitiesReadErrContact', err);
    }
  },

  async activitiesGetDataSingle({ commit }, id) {
    try {
      await commit('activitiesReadBegin');
      const query = await DataService.get(`/activities/${id}/show`);

      let respx = [query.data.success,query.data.resx,query.data.message];
      const xresponse = respx.map(item => JSON.stringify(item))            
      
      await commit('activitiesReadSuccess', query.data.data);
      await commit('activitiesResponse', xresponse);

    } catch (err) {
      await commit('activitiesReadErr', err);
    }
  },

  async activitiesGetDataAll({ commit }) {

    //console.log('activitiesGetDataAll');

    try {
      await commit('activitiesReadBegin');
      const query = await DataService.get('/activities/all');
      //console.log('activitiesGetDataAll query');
      
      let respx = [query.data.success,query.data.resx,query.data.message];
      const xresponse = respx.map(item => JSON.stringify(item))
      //console.log("message", JSON.stringify(query.data.message));
      await commit('activitiesResponse', xresponse);

      await commit('activitiesReadSuccess', query.data.data);
    } catch (err) {
      console.log(err);
      await commit('activitiesReadErr', err);
    }
  },

  async activitiesubmitData({ commit }, data) {   
   
    action.action = "insert";
    action.oggetto = "activities";
     
    await commit('activitiesAddBegin');    
    try {
      const response = await DataService.post('/activities/store', data);

      notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
      await commit('activitiesAddSuccess', response.data.data);
    
      action.oggetto_json = data; //JSON.stringify()
      await DataService.post('/actions/store', action);

    } catch (err) {
      let error = err;
      await commit('activitiesAddErr', err);
      if (typeof err === 'string')
      {    
        console.log(err);
        notificationMessage.addNotificationError(err);
      }
      else {error = JSON.stringify(err);}   

      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  async activitiesUpdateData({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "update";
    action.oggetto_json = data; //JSON.stringify(data);
    
    try {
      await commit('activitiesUpdateBegin');
      const response = await DataService.put(`/activities/${id}/update`, data);
      commit('activitiesUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('activitiesUpdateErr', err);
      if (typeof err === 'string')
      {    
        console.log(err);
        notificationMessage.updateNotificationError(err);
      }
      else {error = JSON.stringify(err);}   

      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },
  
  async activitiesDeleteData({ commit }, { id, data }) { // { id, getData }
    
    action.action = "delete";
    action.oggetto = "delete";
    action.oggetto_json = data; //JSON.stringify(data);
     

    try {
      await commit('activitiesDeleteBegin');
      const response = await DataService.delete(`/activities/${id}/delete`, {});
      await commit('activitiesDeleteSuccess');
      //await getData();      
      notificationMessage.deleteNotificationSuccess(response.data.message);
      
      await DataService.post('/actions/store', action);

    } catch (err) {
      let error = err;
      await commit('activitiesDeleteErr', err);
      if (typeof err === 'string')
      {    
        console.log(err);
        notificationMessage.deleteNotificationError(err);
      }
      else {error = JSON.stringify(err);}   
           
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));


    }
  },
  
  async activitiesSearchData({ commit }, searchItem) {
    try {      
      await commit('activitiesReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/activities/search/${searchItem}`);
        await commit('activitiesReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/activitiess/all');
          await commit('activitiesReadSuccess', query.data.data);
        } catch (err) {
           
          await commit('activitiesReadErr', err);
        }
      }
    } catch (err) {
       
      await commit('activitiesReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
