import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
//import Cookies from 'vue-cookies';
import Cookies from 'js-cookie';

import notificationMessage from '@/config/notificationMessage/notificationMessage';

const action = {
  evento: "authentication",
  task: "users",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: "", //Cookies.get('userid'),
  nominativo: "", //Cookies.get('nominativo'),
  source_ip: "",
  data_action: new Date().toISOString(),
  status: 1
};

const state = () => ({
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
  success: false,
  message: null,
  resx: null,
  data: [],
  roles: Cookies.get('piniz')

});

const actions = {
  
  async logOut({ commit }) {
    try {
      commit('logoutBegin');    
      Cookies.remove('access_token');
      Cookies.remove('logedIn');
      Cookies.remove('committlogin'); 
      Cookies.remove('piniz'); 
      Cookies.remove('userid');
      Cookies.remove('nominativo'); 
      Cookies.remove('email');
      Cookies.remove('ipsocurce');
      commit('logoutSuccess', null);
    } catch (err) {
      commit('logoutErr', err);
    }
  },

  async login({ commit }, data) {   // ToAddTask
    
    action.action = "login";
    action.oggetto = "user";

    try {
      console.log('try DataService.getIpSource');
      action.source_ip = await DataService.getIpAddress();
    } 
    catch (err) {
      console.log('activitiesUpdate getIpSource Err', err);
      action.source_ip = "0.0.0.1";
    }
    finally {
      console.log('activitiesUpdate getIpSource finally');
      action.source_ip = "0.0.0.0";
    }

    await commit('loginBegin');    
    try {
      const response = await DataService.post('/auths/signin', data);
 
      if (!response.data.success){
        notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
        //actionmsg
        await commit('loginErr', response.data.message);
        action.evento = "error";      
        action.oggetto_json = JSON.stringify(data);
        action.descrizione = response.data.actionmsg;
        //const datalog = 
        await DataService.post('/actions/store', action);

      } else if (response.data.success && response.data.resx === "OK") {

        //let rolesOpen = DataService.decryptAES(response.data.roles);
        //console.log(rolesOpen);
        //console.log(JSON.parse(rolesOpen));

        Cookies.set('logedIn', true); //, {expires: '12h', path: '/', domain: '', secure: true }
        Cookies.set('access_token', response.data.token)
        Cookies.set('piniz',response.data.roles);       
        Cookies.set('userid',response.data.data.id); 
        Cookies.set('nominativo',response.data.data.nominativo); 
        Cookies.set('email',response.data.data.email); 
        Cookies.set('ipsocurce',action.source_ip);

        notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
        //await commit('taskAddBeginSuccess', response.data.data);
        await commit('loginSuccess', response.data.data, response.data.roles);
        
        const dataLogin = new Date().toISOString();
        const last_access = { ultimo_accesso: dataLogin};
        
        const dataEcrypted = DataService.encryptAES(JSON.stringify(last_access));

        await DataService.put(`/users/${response.data.data.id}/update`, { datax: dataEcrypted});

        console.log("aggiornamento last_access: " + JSON.stringify(last_access));


        data.ultimo_accesso = dataLogin;
        action.oggetto_json = data; //JSON.stringify()
        action.evento = "success";
        action.utente = Cookies.get('userid'),
        action.nominativo = Cookies.get('nominativo'),

        await DataService.post('/actions/store', action);
        console.log("actions store evento " + action.evento);

      }
      

    } catch (err) {
      //await commit('taskAddBeginErr', err);
      await commit('loginErr', err);
     
      notificationMessage.addNotificationError(err.message);
      
      action.evento = "error";      
      action.oggetto_json = JSON.stringify(err);
      action.descrizione = err.message;
      //const datalog = 
      await DataService.post('/actions/store', action);

    }
  },

  async forgotpassword({ commit }, data) {   // 
    
    action.action = "forgotpassword";
    action.oggetto = "auth";
    action.task = "user";

    //await commit('resetBegin');    
    try {
      // creo il token da inviare per email e aggiorno il dato nella tabella user
      const response = await DataService.post('/auths/createtokenpwd', data);

      let actionmsg = response.data.actionmsg;
      action.note = "creato token password per " . data.datax;

      if (response.data.success && response.data.resx === "OK"){
        // token creato e update ok
        // notifica popup e memorizza action        
        //notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
        
        action.evento = "success";      
        action.oggetto_json = JSON.stringify(data);
        action.descrizione = actionmsg;
        

        await DataService.post('/actions/store', action);   

        // invio email
        // VALUTARE SE MEMORIZZARE LE EMAIL INVIATE 
        let sendemaildata = {
          token: response.data.token,
          email: data.datax
        }

        let responseEmail = await DataService.post('/auths/sendemailtokenpwd', sendemaildata);
        action.note = "invio email reset password a " . data.datax;

        if (responseEmail.data.success && responseEmail.data.resx === "OK"){
          await commit('resetSuccess');
          // token creato e update ok
          // notifica popup e memorizza action        
          notificationMessage.addNotificationRespx(responseEmail.data.resx,responseEmail.data.message);
  
          action.action = "send";
          action.evento = "success";    
             
          action.oggetto_json = JSON.stringify(responseEmail.data.data);
          action.descrizione = responseEmail.data.actionmsg;
          await DataService.post('/actions/store', action);
        } 
        else if (!responseEmail.data.success) {
          await commit('resetErr', responseEmail.data.actionmsg);
          // notifica popup e memorizza action
          notificationMessage.addNotificationRespx(responseEmail.data.resx,responseEmail.data.message);  
          action.task = "invio email reset password";      
          action.evento = "error";
          action.oggetto_json = data; //JSON.stringify()
           
          action.descrizione = responseEmail.data.actionmsg;
          await DataService.post('/actions/store', action);
        }

      } else if (!response.data.success) {

        console.log("no response.data.success: " + response.data.success);

        await commit('resetErr', response.data.actionmsg);
        // notifica popup e memorizza action
        notificationMessage.addNotificationRespx(response.data.resx,response.data.message);        
        action.evento = "error";
        action.oggetto_json = data; //JSON.stringify()        
        action.descrizione = response.data.actionmsg;
        await DataService.post('/actions/store', action);

      }
      

    } catch (err) {
      
      console.log("catch err: " + err.message);

      await commit('resetErr', err);
      notificationMessage.addNotificationError(err.message);
      action.evento = "error";      
      action.oggetto_json = JSON.stringify(err);
      action.descrizione = err.message;
      //const datalog = 
      await DataService.post('/actions/store', action);
    }
  },

  async resetpassword({ commit }, data) {   // 
    
    action.action = "resetpassword";
    action.oggetto = "auth";
    action.task = "user";


    await commit('resetBegin', "Controllo token in corso");    
    try {
      console.log("resetpassword data",JSON.stringify(data) );
      // recupero il token e verifico 
      const response = await DataService.get(`/auths/forgotpassword/${data}`);

      let actionmsg = response.data.actionmsg;
      action.note = "reset password per " . data.datax;

      if (response.data.success && response.data.resx === "OK"){
        // token creato e update ok
        // notifica popup e memorizza action        
        //notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
         
        action.evento = "success";      
        action.oggetto_json = JSON.stringify(data);
        action.descrizione = actionmsg;
        await DataService.post('/actions/store', action);   
 

        await commit('resetSuccess', response.data.message); 

      } else if (!response.data.success) {

        console.log("no response.data.success: " + response.data.success);

        await commit('resetErr', response.data.message);
        // notifica popup e memorizza action
        notificationMessage.addNotificationRespx(response.data.resx,response.data.message);        
        action.evento = "error";
        action.oggetto_json = data; //JSON.stringify()
         
        action.descrizione = response.data.actionmsg;
        await DataService.post('/actions/store', action);

      }
      

    } catch (err) {
      
      console.log("catch err: " + err.message);

      await commit('resetErr', err);
      notificationMessage.addNotificationError(err.message);
      action.evento = "error";      
      action.oggetto_json = JSON.stringify(err);
      action.descrizione = err.message;
      //const datalog = 
      await DataService.post('/actions/store', action);
    }
  },

  async checkAuthSA({ commit }, data) {   // ToAddTask
    
    await commit('checkAuth');    
    try {
      // get cookies piniz
      console.log("data", data);
      console.log("piniz", Cookies.get('piniz'));    
      return Cookies.get('piniz');  // JSON.stringify
    } catch (err) {
      //await commit('taskAddBeginErr', err);
      return 0;    
    }
  },


};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
