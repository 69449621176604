export default {

  //////////////////////////////////////////
  // READ state
  activitiesReadBegin(state) {
    state.loading = true;
  },

  activitiesReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  activitiesReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  
  //////////////////////////////////////////
  // ADD state
  activitiesAddBegin(state) {
    state.loading = true;
    state.success = false;
  },

  activitiesAddSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.success = true;
  },

  activitiesAddErr(state, err) {
    state.loading = false;
    state.error = err;
  },


  //////////////////////////////////////////
  // UPDATE state
  activitiesUpdateBegin(state) {
    state.loading = true;
  },

  activitiesUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  activitiesUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // DELETE state
  activitiesDeleteBegin(state) {
    state.loading = true;
  },

  activitiesDeleteSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  activitiesDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },


  //////////////////////////////////////////
  // RESPONSE 
  activitiesResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
  },

  //////////////////////////////////////////
  // READ state single contact
  activitiesReadBeginContact(state) {
    state.loadingContact = true;
  },

  activitiesReadSuccessContact(state, data) {
    state.loadingContact = false;
    state.dataContact = data;
  },

  activitiesResponseContact(state, data) {
    const xresponse = data.map((s) => JSON.parse(s));
    state.successContact = xresponse[0];
    state.resxContact = xresponse[1];
    state.messageContact = xresponse[2];
  },

  activitiesReadErrContact(state, err) {
    state.loadingContact = false;
    state.errorContact = err;
  },
  

  //////////////////////////////////////////
  // SEARCH
  activitiesSearchBegin(state) {
    state.loading = true;
  },

  activitiesSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  activitiesSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

};
