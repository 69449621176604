//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';
// // // mod token
const authHeader = () => {

  let xacstkn = Cookies.get('access_token');

  if (xacstkn) {
    return { 
      'x-access-token': xacstkn ,
      'Content-Type': 'application/json'
    };       // for Node.js Express back-end
  } else {
    return {};
  }
}

const getCookie = (key) => {
  const data = typeof window !== 'undefined' ? Cookies.get(key) : '';

  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

export { authHeader, getCookie };

