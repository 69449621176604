import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';

// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/plugins/unicons';
import '@/core/components/custom'; // .js  elimina da qui i componenti che non servono 
import '@/core/components/style';

import Antd from 'ant-design-vue/es';
//import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';

import './static/css/style.css';
//import SimpleTypeahead from 'vue3-simple-typeahead';
//import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';

import './registerServiceWorker' 

app.config.productionTip = false;

app.use(Antd);
//app.use(SimpleTypeahead);

app.use(store);
app.use(router);
app.mount('#app');