import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { array } from 'vue-types';
import notificationMessage from '@/config/notificationMessage/notificationMessage';
//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';

const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "utenti",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};

const state = () => ({
  idutente: 0,
  data: [],
  success: false,
  resx: null,
  message: null,
  loading: false,
  error: null,  
  rspn:[], 
  url: null,
  fileLoading: false, 
});


const actions = {
    
  async utentiGetDataSingle({ commit }, id) {
    try {
      await commit('utentiReadBegin');
      const query = await DataService.get(`/users/${id}/show`);

      //console.log('query.data.data: ' + JSON.stringify(query.data.data));
      //console.log('query.data.message: ' + query.data.message);

      //respx[`message`] = query.data.message;
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message];
      const xresponse = respx.map(item => JSON.stringify(item))     
      //console.log('xxrs: ' + xresponse);
      
      await commit('utentiReadSuccess', query.data.data);
      await commit('utentiResponse', xresponse);

    } catch (err) {
      await commit('utentiReadErr', err);
    }
  },

  async utentiGetDataAll({ commit }) {
    try {
      //console.log('utentiGetDataAll');

      await commit('utentiReadBegin');
      const query = await DataService.get('/users/all');
      await commit('utentiReadSuccess', query.data.data);

    } catch (err) {
      //console.log(err);
      await commit('utentiReadErr', err);
    }
  },

  async utentiSubmitData({ commit }, data) {   
   
    action.action = "insert";
    action.oggetto = "utenti";

    //await commit('utentiAddBeginBegin');    
    await commit('utentiAddBegin');    
    try {
      const response = await DataService.post('/users/store', data);

      //console.log("await DataService.post message j: " + JSON.stringify(response.data.message) );
      
      notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
      //await commit('utentiAddBeginSuccess', response.data.data);

      if (response.data.resx === "OK") {
        await commit('utentiAddSuccess', response.data.data);
        //console.log("utentiAddSuccess message: " + response.data.message);        
        action.oggetto_json = data; //JSON.stringify()
        //const datalog = 
        await DataService.post('/actions/store', action);
        //console.log("message: " + JSON.stringify(datalog));
      }
      else await commit('utentiAddErr', response.data.message);


    } catch (err) {
      //await commit('utentiAddBeginErr', err);
      await commit('utentiAddErr', err);
      //console.log(JSON.stringify(err));
      notificationMessage.addNotificationError(err.message);
      
      action.evento = "error";      
      action.oggetto_json = err ;//JSON.stringify(err);
      action.descrizione = "errore inserimento dati";
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
  },

  async utentiUpdateData({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "utenti";
    action.oggetto_json = data; //JSON.stringify(data);
     

    try {
      await commit('utentiUpdateBegin');
      const response = await DataService.put(`/users/${id}/update`, data);
      commit('utentiUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      await commit('utentiUpdateErr', err);
      notificationMessage.updateNotificationError(err);

      action.oggetto = "errore";
      action.descrizione = err;
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
  },
  
  async utentiDeleteData({ commit }, { id, getData }) {
    try {
      await commit('utentiDeleteBegin');
      const response = await DataService.delete(`/users/${id}/delete`, {});
      await commit('utentiDeleteSuccess');
      await getData();      
      notificationMessage.deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('utentiDeleteErr', err);
      //console.log(err);
      notificationMessage.deleteNotificationError(err);
    }
  },

  async usersearchData({ commit }, searchItem) {
    try {      
      await commit('utentiReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/users/search/${searchItem}`);
        await commit('utentiReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/users/all');
          await commit('utentiReadSuccess', query.data.data);
        } catch (err) {
          //console.log(err);
          await commit('utentiReadErr', err);
        }
      }
    } catch (err) {
      //console.log(err);
      await commit('utentiReadErr', err);
    }
  },

  async usersearchStatus({ commit }, searchItem) {
    try {
      await commit('utentiReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/users/status/${searchItem}`);
        await commit('utentiReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/users/all');
          await commit('utentiReadSuccess', query.data.data);
        } catch (err) {
          //console.log(err);
          await commit('utentiReadErr', err);
        }
      }
    } catch (err) {
      //console.log(err);
      await commit('utentiReadErr', err);
    }
  },

  async utentiDataLog({ commit }, data ) {
    
    action.oggetto = data.oggetto;
    action.oggetto_json = JSON.stringify(data);
    
    await commit('utentiAddBeginBegin');    
    try {
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));
      //await commit('utentiAddBeginSuccess', "inserimento dati");

    } catch (err) {
      await commit('utentiAddBeginErr', err);
      //console.log(err);
      notificationMessage.addNotificationError(err.message);
      
      action.oggetto = "errore";            
      action.descrizione = err;
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
    
  },

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////

  async userstoreDocument({ commit }, data) {   
       
    action.action = "insert";
    action.oggetto = data.oggetto;
    action.oggetto_json = data; //JSON.stringify(data);
    action.data_action = new Date().toISOString();

    await commit('utentiDocumentBegin');    
    try {
      const response = await DataService.post('/documents/store', data);
      notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
      await commit('utentiDocumentStoreSuccess', response.data);
      
      // cancello il file blob thumburl altrimenti errore nel memorizzare oggettoJson in action 
      response.data.data.thumburl = "";
      
      //let objjson = delete response.data.data[0].thumburl;
      ////console.log("objjson: " + JSON.stringify(objjson));

      action.task = "documents"; //data.reference;
      action.task_id = response.data.data.id; //data.reference_id;
      //action.taskOid = data.reference_id;
      action.oggetto = data.nome;
      action.oggetto_json = response.data.data; //JSON.stringify(data);
      action.descrizione = "inserimento documento";
      action.note = "inserimento documento id " + response.data.data.id + " per il bando id " + data.reference_id;

      await DataService.post('/actions/store', action);
      //const datalog = await DataService.post('/actions/store', action);
      ////console.log("message: " + JSON.stringify(datalog));

    } catch (err) {
      await commit('utentiDocumentErr', err);
      //console.log(err);
      notificationMessage.addNotificationError(err.message);
      
      action.evento = "error";
      action.oggetto = err; //JSON.stringify(err);      
      action.oggetto_json = data; //JSON.stringify(data);
      action.descrizione = "errore inserimento documento";
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
  },
  
  async utentiBandiDataDelete({ commit }, data) {   
     
    action.action = "delete";
    action.oggetto = data.xdata.nomeorig;
    action.oggetto_json = data.xdata; //JSON.stringify(data);
    action.data_action = new Date().toISOString();
    action.task_id = data.xdata.iddox; //data.reference_id;
    //action.taskOid = data.xdata.guid;

    //console.log("data.xdata: ", JSON.stringify(data.xdata));

    //iddox: id,
    //reference: reference,
    //reference_id: reference_id,
    //nomefile: filename,
    //guid: uuid,
    //nomeorig: nome 

    await commit('utentiBandiDeleteBegin');    
    try {
      //console.log("try");
    } catch (err) {
      //console.log("catch");
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  async utentiBandiDataGet({ commit }, id) {
    try {
      await commit('utentiDocumentBegin');
      const query = await DataService.get(`/documents/${id}/files`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));     
      await commit('utentiDocumentResponse', xresponse);
    } catch (err) {
      await commit('utentiDocumentErr', err);
    }
  },

  async utentiBandiDataList({ commit }) { //status
    try {
      await commit('utentiBandiListBegin');
      const query = await DataService.get(`/documents/all`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));         
      await commit('utentiBandiListResponse', xresponse);
    } catch (err) {
      await commit('utentiBandiListErr', err);
    }
  },

  async utentiBandiSearch({ commit }, searchItem) {
    try {
      await commit('utentiDocumentBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/documents/search/${searchItem}`);
        //await commit('utentiReadSuccess', query.data.data);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));     
        await commit('utentiDocumentResponse', xresponse);
      } else {
        try {
          const query = await DataService.get('/documents/all');
          //await commit('utentiReadSuccess', query.data.data);
          let respx = array();
          respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
          const xresponse = respx.map(item => JSON.stringify(item));     
          await commit('utentiDocumentResponse', xresponse);
        } catch (err) {
          //console.log(err);
          await commit('utentiDocumentErr', err);
        }
      }
    } catch (err) {
      //console.log(err);
      await commit('utentiDocumentErr', err);
    }
  },

  async dataLiveFilter ({commit}, {value, key, datax}) {    
    try {
      await commit('dataLiveFilterBegin');
   
      //console.log("value: " + JSON.stringify(value));
      //console.log("key: " + JSON.stringify(key));
      //console.log("message: " + JSON.stringify(datax));

      let datalist = JSON.parse(datax);
      let datafiltered = datalist.filter(item => item[key].toLowerCase().startsWith(value.toLowerCase()));
      
      /*
        let datafilter1 =  datalist.filter(
            (item) => item.id == itemx
        );
        let datafilter2 = datalist.filter(
            item => item[key].toLowerCase().startsWith(value.toLowerCase())
        );
        let datafilter3 = datalist.filter((item) => {
          return item.nome.startsWith(value);
        });
      */      
      await commit('dataLiveFilterSuccess', datafiltered);
    } catch (err) {
      await commit('dataLiveFilterErr', err);
    }    
  },

  async filterDataByKey({ commit }, {value, key, valueall, datax}) {
    try {
      //console.log('filterDataByKey');

      commit('dataLiveFilterBegin');
      let datalist = JSON.parse(datax);

      const data = datalist.filter((item) => {
        if (value.toString() !== valueall.toString()) {
          return item[key].toString() === value.toString();
          
        }
        return datalist;
      });
      commit('dataLiveFilterSuccess', data);
    } catch (err) {
      commit('dataLiveFilterErr', err.toString());
    }
  },

  async sortingDataByKey({ commit }, {sortBy, datax}) {
    try {
      //console.log('sortingDataByKey');

      commit('dataLiveFilterBegin');
      let datalist = JSON.parse(datax);
      const data = datalist.sort((a, b) => {
        return b[sortBy] - a[sortBy];
      });
      setTimeout(() => {
        commit('dataLiveFilterSuccess', data);
      }, 500);
    } catch (err) {
      commit('dataLiveFilterErr', err);
    }
  },


  async LiveFilter ({commit}, data) {    
    try {
      commit('LiveFilterBegin');
      commit('LiveFilterSuccess', data);
    } catch (err) {
      commit('LiveFilterErr', err);
    }    
  },

  ////////////////////////////////////////////////////////
  // // // REPORT // // // // // // // // // //

  async utentiGetStatus({ commit } ) {
     
    try {
      await commit('utentiReportStatusBegin');      
        const query = await DataService.get(`/users/report/status`);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));  
        
        //console.log("query.data.data: " + JSON.stringify(query.data.data));

        await commit('utentiReportStatusResponse', xresponse);
    } catch (err) {
      //console.log(err);
      await commit('utentiReportStatusErr', err);
    }
  },

  async utentiGetCicloVita({ commit } ) {
     
    try {
      await commit('utentiReportCicloVitaBegin');      
        const query = await DataService.get(`/users/report/ciclovita`);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));   
        await commit('utentiReportCicloVitaResponse', xresponse);
    } catch (err) {
      //console.log(err);
      await commit('utentiReportCicloVitaErr', err);
    }
  },

  



};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};