export default {
  toAddBoardBegin(state) {
    state.loading = true;
  },

  toAddBoardSuccess(state, data) {
    state.loading = false;
    state.boardData = data;
  },

  toAddBoardErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  toAddTaskBegin(state) {
    state.tsLoading = true;
  },

  toAddTaskSuccess(state, data) {
    state.tsLoading = false;
    state.taskData = data;
  },

  toAddTaskErr(state, err) {
    state.tsLoading = false;
    state.error = err;
  },

  toDeleteTaskBegin(state) {
    state.tsLoading = true;
  },

  toDeleteTaskSuccess(state, data) {
    state.tsLoading = false;
    state.taskData = data;
  },

  toDeleteTaskErr(state, err) {
    state.tsLoading = false;
    state.error = err;
  },

  ////////////////////////////////
  //////////////////////////////////////////
  // ADD state
  kanbanAddBegin(state) {
    state.loading = true;
    state.success = false;
  },

  kanbanAddSuccess(state, data) {
    state.loading = false;
    state.boardData = data;
    state.success = true;
  },

  kanbanAddErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  kanbanAddBeginBegin(state) {
    state.loading = true;
  },

  kanbanAddBeginSuccess(state, data) {
    state.loading = false;
    state.boardData = data;
  },

  kanbanAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // READ state
  kanbanReadBegin(state) {
    state.loading = true;
  },

  kanbanReadSuccess(state, data) {
    state.loading = false;
    state.boardData = data;
  },

  kanbanReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // UPDATE state
  kanbanUpdateBegin(state) {
    state.loading = true;
  },

  kanbanUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  kanbanUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // DELETE state
  kanbanDeleteBegin(state) {
    state.loading = true;
  },

  kanbanDeleteSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  kanbanDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },


  //////////////////////////////////////////
  // RESPONSE 
  kanbanResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
  },

  //////////////////////////////////////////
  // SEARCH
  kanbanSearchBegin(state) {
    state.loading = true;
  },

  kanbanSearchSuccess(state, data) {
    state.loading = false;
    state.boardData = data;
  },

  kanbanSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
