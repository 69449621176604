export default {

  //////////////////////////////////////////
  // ADD state
  contactAddBegin(state) {
    state.loading = true;
    state.success = false;
  },

  contactAddSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.success = true;
  },

  contactAddErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactAddBeginBegin(state) {
    state.loading = true;
  },

  contactAddBeginSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  contactAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // READ state
  contactReadBegin(state) {
    state.loading = true;
  },

  contactReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.dataorig = data;
    // state.dataSearch = data;
    
  },

  contactReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // UPDATE state
  contactUpdateBegin(state) {
    state.loading = true;
  },

  contactUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  contactUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // DELETE state
  contactDeleteBegin(state) {
    state.loading = true;
  },

  contactDeleteSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  contactDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },


  //////////////////////////////////////////
  // RESPONSE 
  contactResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
  },

  //////////////////////////////////////////
  // SEARCH
  contactSearchBegin(state) {
    state.loading = true;
  },

  contactSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.dataSearch = data;
  },

  contactSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  /////////////////////////////////////////
  //////////     Document     /////////////
  /////////////////////////////////////////
  contactDocumentBegin(state) {
    state.docsloading = true;
    state.docsreloading = false;
  },
  contactDocumentSuccess(state, data) {
    state.docsloading = false;
    state.docsdata = data;
  },
  contactDocumentErr(state, err) {
    state.docsloading = false;
    state.docserror = err;
    state.docsreloading = true;
  },
  contactDocumentResponse(state, data) {
    state.docsreloading = false;
    state.docsloading = false;
    const docsresponse = data.map((s) => JSON.parse(s));
    state.docssuccess = docsresponse[0];
    state.docsresx = docsresponse[1];
    state.docsmessage = docsresponse[2];
    state.docsdata = docsresponse[3];
  },
  contactDocumentStoreSuccess(state, data) {
    state.docsloading = false;
    state.docsdata = data.data;
    state.docsreloading = data.success;
  },
  contactDocumentDeleteBegin(state) {
    state.docsloading = true;
    state.docsreloading = false;
  },
  contactDocumentDeleteSuccess(state, data) {
    state.docsloading = false;
    state.docsdata = data;
    state.docsreloading = data.success;
  },
  contactDocumentDeleteErr(state, err) {
    state.docsloading = false;
    state.docserror = err;
    state.docsreloading = true;
  },
  contactDocumentDownloaded(state) {
    state.docsloading = false;
    state.docsreloading = true;
  },

  //////////////////////////////////////////
  // FILTER 
  LiveFilterBegin(state) {
    state.docsloading = true;
    state.docsreloading = false;
  },
  LiveFilterSuccess(state, data) {
    state.docsloading = false;
    //state.docsdata = data;
    state.docslistdata = data;
  },
  LiveFilterErr(state, err) {
    state.docsloading = false;
    state.docserror = err;
    state.docsreloading = true;
  },
  ///////////////////////////////
  dataLiveFilterBegin(state) {
    state.docslistloading = true;
  },
  dataLiveFilterSuccess(state, data) {
    state.docslistloading = false;
    state.docslistdata = data;    
  },
  dataLiveFilterErr(state, err) {
    state.docslistloading = false;
    state.docslisterror = err;
  },
  ///////////////////////////////
  contactDocsListBegin(state) {
    state.docslistloading = true;   
  },
  dataDocsListSuccess(state, data) {
    state.docslistloading = false;
    state.docslistdata = data;
    state.docslistdataorig = data;
  },
  contactDocsListResponse(state, data) {
    state.docslistloading = false;
    const docsresponse = data.map((s) => JSON.parse(s));
    state.docslistsuccess = docsresponse[0];
    state.docslistresx = docsresponse[1];
    state.docslistmessage = docsresponse[2];
    state.docslistdata = docsresponse[3];
    state.docslistdataorig = docsresponse[3];
  },
  contactDocsListErr(state, err) {
    state.docslistloading = false;
    state.docslisterror = err;
  },
  ///////////////////////////////
  contactBandiListBegin(state) {
    state.docslistloading = true;   
  },
  dataListSuccess(state, data) {
    state.docslistloading = false;
    state.docslistdata = data;
    state.docslistdataorig = data;
  },
  contactBandiListResponse(state, data) {
    state.docslistloading = false;
    const docsresponse = data.map((s) => JSON.parse(s));
    state.docslistsuccess = docsresponse[0];
    state.docslistresx = docsresponse[1];
    state.docslistmessage = docsresponse[2];
    state.docslistdata = docsresponse[3];
    state.docslistdataorig = docsresponse[3];
  },
  contactBandiListErr(state, err) {
    state.docslistloading = false;
    state.docslisterror = err;
  },

  //////////////////////////////////////////////
  // REPORTING

  contactReportStatusBegin(state) {
    state.reportStatusLoading = true;
  },
  contactReportStatusSuccess(state, data) {
    state.reportStatusLoading = false;
    state.reportStatusData = data;
  },
  contactReportStatusErr(state, err) {
    state.reportStatusLoading = false;
    state.reportStatusError = err;
  },
  contactReportStatusResponse(state, data) {
    state.reportStatusLoading = false;
    const dataresponse = data.map((s) => JSON.parse(s));
    state.reportStatusSuccess = dataresponse[0];
    state.reportStatusResx = dataresponse[1];
    state.reportStatusMessage = dataresponse[2];
    state.reportStatusRespData = dataresponse[3];

    state.reportStatusData = dataresponse[3];
  },
  ////////////////////////////////////////////////
  
  contactReportCicloVitaBegin(state) {
    state.reportCicloVitaLoading = true;
  },
  contactReportCicloVitaSuccess(state, data) {
    state.reportCicloVitaLoading = false;
    state.reportCicloVitaData = data;
  },
  contactReportCicloVitaErr(state, err) {
    state.reportCicloVitaLoading = false;
    state.reportCicloVitaError = err;
  },
  contactReportCicloVitaResponse(state, data) {
    state.reportCicloVitaLoading = false;
    const dataresponse = data.map((s) => JSON.parse(s));
    state.reportCicloVitaSuccess = dataresponse[0];
    state.reportCicloVitaResx = dataresponse[1];
    state.reportCicloVitaMessage = dataresponse[2];
    state.reportCicloVitaRespData = dataresponse[3];

    state.reportCicloVitaData = dataresponse[3];
  },
  ////////////////////////////////////////////////
  

};