import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import notificationMessage from '@/config/notificationMessage/notificationMessage';
//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';
/*

import { notification } from 'ant-design-vue';

const addNotificationRespx = (respx,msg) => {
  if (respx==="OK") addNotificationSuccess(msg);
  if (respx==="ALERT") addNotificationWarning(msg); 
  if (respx==="KO") addNotificationError(msg);   
};

const addNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been Submited',
  });
};

const addNotificationWarning = (msg) => {
  notification.warning({
    message: msg, //'alert',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};
*/

const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};


const state = () => ({
  boardData: [],
  taskData: [],
  checklistData: [],
  checklistitemData: [],
  loading: false,
  tsLoading: false,
  error: null,
  data: [],
  success: false,
  resx: null,
  message: null,
});

const actions = {

  async kanbanGetDataAll({ commit }) {
    try {
      await commit('kanbanReadBegin');

      console.log('kanbanReadBegin');


      const query = await DataService.get('/kanbanboards/all');
      console.log(query.data.data);
      await commit('kanbanReadSuccess', query.data.data);
    } catch (err) {
      console.log(err);
      await commit('kanbanReadErr', err);
    }
  },

  async kanbanSubmitData({ commit }, data) {   
   
    action.action = "insert";
    action.oggetto = "kanban";

     
    await commit('kanbanAddBeginBegin');    
    try {
      const response = await DataService.post('/kanbanboards/store', data);
      
      notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
      //await commit('kanbanAddBeginSuccess', response.data.data);
      await commit('kanbanAddSuccess', response.data.data);
      console.log("kanbanAddSuccess message: " + response.data.message);

      action.oggetto_json = data; //JSON.stringify()
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } catch (err) {
      //await commit('kanbanAddBeginErr', err);
      await commit('kanbanAddErr', err);
      console.log(err);
      notificationMessage.addNotificationError(err.message);
      
      action.evento = "error";      
      action.oggetto_json = err ;//JSON.stringify(err);
      action.descrizione = "errore inserimento dati";
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  async kanbanUpdateData({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "update";
    action.oggetto_json = data; //JSON.stringify(data);
     
    

    try {
      await commit('kanbanUpdateBegin');
      const response = await DataService.put(`/kanbanboards/${id}/update`, data);
      commit('kanbanUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      await commit('kanbanUpdateErr', err);
      notificationMessage.updateNotificationError(err);

      action.oggetto = "errore";
      action.descrizione = err;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },
  
  async kanbanDeleteData({ commit }, id) { // { id, getData }
    try {
      await commit('kanbanDeleteBegin');
      const response = await DataService.delete(`/kanbanboards/${id}/delete`, {});
      await commit('kanbanDeleteSuccess');
      //await getData();      
      notificationMessage.deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('kanbanDeleteErr', err);
      console.log(err);
      notificationMessage.deleteNotificationError(err);
    }
  },

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  
  async ToAddBoard({ commit }, data) {
    try {
      commit('toAddBoardBegin');
      commit('toAddBoardSuccess', data);
    } catch (err) {
      commit('toAddBoardErr', err);
    }
  },

  async ToAddTask({ commit }, data) {
    try {
      commit('toAddTaskBegin');
      commit('toAddTaskSuccess', data);
    } catch (err) {
      commit('toAddTaskErr', err);
    }
  },

  async ToDeleteTask({ commit }, data) {
    try {
      commit('toDeleteTaskBegin');
      commit('toDeleteTaskSuccess', data);
    } catch (err) {
      commit('toDeleteTaskErr', err);
    }
  },
};
export default {
  namespaced: false,
  state,
  actions,
  mutations,
  notificationMessage,
};
