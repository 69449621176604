export default {

  //////////////////////////////////////////
  // ADD state
 utentiAddBegin(state) {
    state.loading = true;
    state.success = false;
  },

 utentiAddSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.success = true;
  },

 utentiAddErr(state, err) {
    state.loading = false;
    state.error = err;
  },

 utentiAddBeginBegin(state) {
    state.loading = true;
  },

 utentiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

 utentiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // READ state
 utentiReadBegin(state) {
    state.loading = true;
  },

 utentiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

 utentiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // UPDATE state
 utentiUpdateBegin(state) {
    state.loading = true;
  },

 utentiUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

 utentiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // DELETE state
 utentiDeleteBegin(state) {
    state.loading = true;
  },

 utentiDeleteSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

 utentiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },


  //////////////////////////////////////////
  // RESPONSE 
  utentiResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
  },

  //////////////////////////////////////////
  // SEARCH
 utentiSearchBegin(state) {
    state.loading = true;
  },

 utentiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

 utentiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // FILTER 
  LiveFilterBegin(state) {
    state.docsloading = true;
    state.docsreloading = false;
  },
  LiveFilterSuccess(state, data) {
    state.docsloading = false;
    //state.docsdata = data;
    state.docslistdata = data;
  },
  LiveFilterErr(state, err) {
    state.docsloading = false;
    state.docserror = err;
    state.docsreloading = true;
  },
  ///////////////////////////////
  dataLiveFilterBegin(state) {
    state.docslistloading = true;
  },
  dataLiveFilterSuccess(state, data) {
    state.docslistloading = false;
    state.docslistdata = data;    
  },
  dataLiveFilterErr(state, err) {
    state.docslistloading = false;
    state.docslisterror = err;
  },
  
  

};