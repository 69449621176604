import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';
import { array } from 'vue-types';
//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';


const addNotificationTemp = (respx,msg) => {
  if (respx==="OK") addNotificationSuccess(msg);
  if (respx==="ALERT") addNotificationWarning(msg); 
  if (respx==="KO") addNotificationError(msg);   
};

const addNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been Submited',
  });
};

const addNotificationWarning = (msg) => {
  notification.warning({
    message: msg, //'alert',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};


const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};



const state = () => ({
  idbando: 0,
  data: [],
  success: false,
  resx: null,
  message: null,
  rspn:[], 
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
  ///////////////////////////////
  docsloading: false,
  docsreloading: false,
  docsdata: [],
  docserror: null,
  docssuccess: false,
  docsresx: null,
  docsmessage: null,
  ///////////////////////////////
  tableData: [],
  docslistdataorig: [],
  ///////////////////////////////
  docslistloading: false,
  docslistdata: [],
  docslisterror: null,
  docslistsuccess: false,
  docslistresx: null,
  docslistmessage: null,
  ///////////////////////////////
  dataReport: [],
  
  dataApertura: [],  
  dataReportloading: false,
  dataReporterror: null,
  dataReportsuccess: false,
  dataReportresx: null,
  dataReportmessage: null,
  
  dataChiusura: [],
  dataReportChiusuraloading: false,
  dataReportChiusuraerror: null,
  dataReportChiusurasuccess: false,
  dataReportChiusuraresx: null,
  dataReportChiusuramessage: null,
  
});

const actions = {

  

  async axiosCrudSubmitData({ commit }, data) {   
   
    action.action = "insert";
    action.oggetto = "insert";

    //await commit('axiosAddBeginBegin');    
    await commit('axiosAddBegin');    
    try {
      const response = await DataService.post('/vuedataxs/store', data);
      addNotificationTemp(response.data.resx,response.data.message);
      //await commit('axiosAddBeginSuccess', response.data.data);
      await commit('axiosAddSuccess', response.data.data);
      //console.log("axiosAddSuccess message: " + response.data.message);

      action.oggetto_json = data; //JSON.stringify()
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    } catch (err) {
      //await commit('axiosAddBeginErr', err);
      await commit('axiosAddErr', err);
      //console.log(err);
      addNotificationError(err.message);
      
      action.oggetto = "errore";      
      action.oggetto_json = err ;//JSON.stringify(err);
      action.descrizione = "errore inserimento dati";
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
  },
 
  async axiosCrudGetData({ commit }) {
    try {
      await commit('axiosReadBegin'); 
      const query = await DataService.get('/vuedataxs/all');
      await commit('axiosReadSuccess', query.data.data);
    } catch (err) {
      //console.log(err);
      await commit('axiosReadErr', err);
    }
  },

  async axiosSingleDataGet({ commit }, id) {
    try {
      await commit('axiosSingleDataBegin');
      const query = await DataService.get(`/vuedataxs/${id}/show`);

      ////console.log('query.data.message: ' + query.data.message);
      //respx[`message`] = query.data.message;
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message];
      const xresponse = respx.map(item => JSON.stringify(item))     

      
      await commit('axiosSingleDataSuccess', query.data.data);
      await commit('axiosResponse', xresponse);

    } catch (err) {
      await commit('axiosSingleDataErr', err);
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  async axiosDocsDataGet({ commit }, id) {
    try {
      await commit('axiosDocumentBegin');

      //console.log('axiosDocsDataGet id: ' + id);
      const query = await DataService.get(`/documents/${id}/files`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));     
      
      
      //console.log('xresponse', JSON.stringify(query.data.data));


      await commit('axiosDocumentResponse', xresponse);
    } catch (err) {
      await commit('axiosDocumentErr', err);
    }
  },

  async axiosFileListGet({ commit }, id) {
    try {
      await commit('axiosContactDocumentBegin');

      const query = await DataService.get(`/documents/${id}/cnctfiles`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));     
      
      await commit('axiosContactDocumentResponse', xresponse);
    } catch (err) {
      await commit('axiosContactocumentErr', err);
    }
  },

  async axiosDocsDataList({ commit }) { //status
    try {
      await commit('axiosDocsListBegin');
      const query = await DataService.get(`/documents/all`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item)); 
      await commit('axiosDocsListResponse', xresponse);
    } catch (err) {
      await commit('axiosDocsListErr', err);
    }
  },

  async axiosDocsSearch({ commit }, searchItem) {
    try {
      await commit('axiosDocumentBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/documents/search/${searchItem}`);
        //await commit('axiosReadSuccess', query.data.data);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));     
        await commit('axiosDocumentResponse', xresponse);
      } else {
        try {
          const query = await DataService.get('/documents/all');
          //await commit('axiosReadSuccess', query.data.data);
          let respx = array();
          respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
          const xresponse = respx.map(item => JSON.stringify(item));     
          await commit('axiosDocumentResponse', xresponse);
        } catch (err) {
          //console.log(err);
          await commit('axiosDocumentErr', err);
        }
      }
    } catch (err) {
      //console.log(err);
      await commit('axiosDocumentErr', err);
    }
  },

  async dataDocsLiveFilter ({commit}, {value, key, datax}) {    
    try {
      await commit('dataDocsLiveFilterBegin');
   
      ////console.log("value: " + JSON.stringify(value));
      ////console.log("key: " + JSON.stringify(key));
      ////console.log("message: " + JSON.stringify(datax));

      let datalist = JSON.parse(datax);
      let datafiltered = datalist.filter(item => item[key].toLowerCase().includes(value.toLowerCase()));
      // includes invece di startsWith
      /*
        let datafilter1 =  datalist.filter(
            (item) => item.id == itemx
        );
        let datafilter2 = datalist.filter(
            item => item[key].toLowerCase().startsWith(value.toLowerCase())
        );
        let datafilter3 = datalist.filter((item) => {
          return item.nome.startsWith(value);
        });
      */      
      await commit('dataDocsLiveFilterSuccess', datafiltered);
    } catch (err) {
      await commit('dataDocsLiveFilterErr', err);
    }    
  },

  async filterDocsByKey({ commit }, {value, key, valueall, datax}) {
    try {
      //console.log('filterDocsByKey');

      commit('dataDocsLiveFilterBegin');
      let datalist = JSON.parse(datax);

      const data = datalist.filter((item) => {
        if (value.toString() !== valueall.toString()) {
          return item[key].toString() === value.toString();
          
        }
        return datalist;
      });
      commit('dataDocsLiveFilterSuccess', data);
    } catch (err) {
      commit('dataDocsLiveFilterErr', err.toString());
    }
  },

  async sortingDocsByKey({ commit }, {sortBy, datax}) {
    try {
      //console.log('sortingDocsByKey');

      commit('dataDocsLiveFilterBegin');
      let datalist = JSON.parse(datax);
      const data = datalist.sort((a, b) => {
        return b[sortBy] - a[sortBy];
      });
      setTimeout(() => {
        commit('dataDocsLiveFilterSuccess', data);
      }, 500);
    } catch (err) {
      commit('dataDocsLiveFilterErr', err);
    }
  },


  async axiosDownloadFile({ commit }, docx) {
    try {
      //await commit('axiosDocumentBegin'); 

      //const response = await DataService.post('/documents/download', data);
      //await commit('axiosDocumentResponse', response);

      await DataService.get(`/documents/${docx}/download/`);
      //await commit('axiosDocumentDownloaded');

    } catch (err) {
      //console.log(err);
      await commit('axiosDocumentErr', err);
    }
  },


  async dataLiveFilter ({commit}, data) {    
    try {
      commit('dataLiveFilterBegin');
      commit('dataLiveFilterSuccess', data);
    } catch (err) {
      commit('dataLiveFilterErr', err);
    }    
  },
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////post////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////


  async axiosDataUpdate({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "update";
    action.oggetto_json = data; //JSON.stringify(data);
       

    try {
      await commit('axiosUpdateBegin');
      const response = await DataService.put(`/vuedataxs/${id}/update`, data);
      commit('axiosUpdateSuccess');
      updateNotificationSuccess(response.data.message);
      
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

      //window.location.href = "/bandi/view";
      //window.location.replace("/bandi/view");

    } 
    catch (err) {
      await commit('axiosUpdateErr', err);
      updateNotificationError(err);

      action.oggetto = "errore";
      action.descrizione = err;
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
  },
  
  async axiosDataDelete({ commit }, { id }) { //id, getData
    try {
      await commit('axiosDeleteBegin');
      const response = await DataService.delete(`/vuedataxs/${id}/delete`, {});
      await commit('axiosDeleteSuccess');
      //await getData();      
      deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosDeleteErr', err);
      //console.log(err);
      deleteNotificationError(err);
    }
  },

  async axiosDataSearch({ commit }, searchItem) {
    try {
      await commit('axiosReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/vuedataxs/search/${searchItem}`);
        await commit('axiosReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/vuedataxs/all');
          await commit('axiosReadSuccess', query.data.data);
        } catch (err) {
          //console.log(err);
          await commit('axiosReadErr', err);
        }
      }
    } catch (err) {
      //console.log(err);
      await commit('axiosReadErr', err);
    }
  },

  ////////////////////////////////////////////////////////
    
  async axiosStoreDocument({ commit }, data) {   
       
    action.action = "insert";
    action.oggetto = data.oggetto;
    action.oggetto_json = data; // JSON.stringify(data);
    action.data_action = new Date().toISOString();

    await commit('axiosDocumentBegin');    
    try {

      ////console.log("nome: ", data.nome);
      ////console.log("filename: ", data.filename);
      ////console.log("data prima di post: " + JSON.stringify(data));
      ////console.log("data ---> ", new Date().toISOString());

      const response = await DataService.post('/documents/store', data);
      addNotificationTemp(response.data.resx,response.data.message);
      await commit('axiosDocumentStoreSuccess', response.data);

      ////console.log("response.data.data.nome: " + JSON.stringify(response.data.data.nome));
      ////console.log("data ---> ", new Date().toISOString());
      ////console.log("");

      // cancello il file blob thumburl altrimenti errore nel memorizzare oggettoJson in action 
      response.data.data.thumburl = "";
      
      //let objjson = delete response.data.data[0].thumburl;

      action.task = "document"; //data.reference;
      action.task_id = response.data.data.document_id; //data.reference_id;
      //action.taskOid = data.reference_id;
      action.oggetto = data.nome;
      action.oggetto_json = response.data.data; // JSON.stringify(data); //
      action.descrizione = "inserimento documento";
      action.note = "inserimento documento id " + response.data.data.document_id + " per il bando id " + data.reference_id;

      ////console.log("@@@action prima di store: " + JSON.stringify(action));
      ////console.log("@@@action.note prima di store: " , action.note, " nome " , data.nome);

      //await DataService.post('/actions/store', action);
      //const datalog = 
      await DataService.post('/actions/store', action);
      ////console.log("message: " + JSON.stringify(datalog));
      ////console.log("actions/store oggetto: " + datalog.data.data.oggetto);
      
      

    } catch (err) {
      await commit('axiosDocumentErr', err);
      //console.log(err);
      addNotificationError(err.message);
      
      action.evento = "error";
      action.oggetto = JSON.stringify(err); //     err; //
      action.oggetto_json = data; //JSON.stringify(data);
      action.descrizione = "errore inserimento documento";
      await DataService.post('/actions/store', action);
    }
  },

  async axiosDocsDataDelete({ commit }, data) {   
     
    action.action = "delete";
    action.oggetto = data.xdata.nomeorig;
    action.oggetto_json = data.xdata; //JSON.stringify(data);
    action.data_action = new Date().toISOString();
    action.task_id = data.xdata.iddox; //data.reference_id;
    //action.taskOid = data.xdata.guid;

    //console.log("data.xdata: ", JSON.stringify(data.xdata));

    //iddox: id,
    //reference: reference,
    //reference_id: reference_id,
    //nomefile: filename,
    //guid: uuid,
    //nomeorig: nome 

    await commit('axiosDocumentDeleteBegin');    
    try {
      const response = await DataService.delete('/documents/delete', data);      
      addNotificationTemp(response.data.resx,response.data.message);      
      await commit('axiosDocumentDeleteSuccess', response.data);
      action.evento = "success";      
      action.task = "documents"; //data.reference;
      action.descrizione = "cancellazione documento";
      action.note = "cancellazione documento id " + data.iddox + " - nome orig:" + data.nomeorig;

      await DataService.post('/actions/store', action);
      ////const datalog = 
      await DataService.post('/actions/store', action);

      // MOVE FILE TO TRASH 
      const responsemoved = await DataService.post('/documents/movetox', data);
      //console.log("responsemoved: " + JSON.stringify(responsemoved));
      //console.log("responsemoved: " + responsemoved.data.message);
      addNotificationTemp(responsemoved.data.resx,responsemoved.data.message);
      
      action.evento = responsemoved.evento;
      action.task = "filesystem"; //data.reference;
      action.descrizione = "Spostamento documento";
      action.note = responsemoved.description;

      await DataService.post('/actions/store', action);
            
      

    } catch (err) {
      await commit('axiosDocumentDeleteErr', err);
      //console.log(err);
      addNotificationError(err.message);
      
      action.evento = "error";
      action.oggetto = err.message; //JSON.stringify(err);      
      action.descrizione = "errore inserimento documento";

      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
  },
  ////////////////////////////////////////////////////
  async axiosDataLog({ commit }, data ) {
    
    action.oggetto = data.oggetto;
    action.oggetto_json = JSON.stringify(data);

    await commit('axiosAddBeginBegin');    
    try {
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));
      //await commit('axiosAddBeginSuccess', "inserimento dati");

    } catch (err) {
      await commit('axiosAddBeginErr', err);
      //console.log(err);
      addNotificationError(err.message);
      
      action.oggetto = "errore";            
      action.descrizione = err;
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
    
  },

  ////////////////////////////////////////////////////
  
  async axiosFileClear({ commit }) {
    try {
      await commit('axiosUploadBegin');
      commit('axiosUploadSuccess', null);
    } catch (err) {
      await commit('axiosUploadErr', err);
    }
  },

  async axiosFileUploader({ commit }, xFile) {
    await commit('axiosUploadBegin');
    let data = new FormData();
    
    //data.set('image', imageAsFile);
    data.set("file", xFile);
    //data.append('file', fs.createReadStream(path), { filepath: path, filename: basename(path) });
    data.append('campo1', 'valore1');
    data.append('metadata', new Blob([JSON.stringify({ name: xFile.name, parents: 'parents' })], { type: 'application/json' }));
    
    addNotificationSuccess(data.get('idbando'));

    try {
      //await delay(1000);
      const query = await DataService.post(
        '/documents/uploadfile', 
        data,
        { 'Content-Type': 'multipart/form-data' }
        );
      commit('axiosUploadSuccess', `${query.data}`);
    } catch (err) {
      await commit('axiosUploadErr', err);
    }

  

  },

  async axiosImageUploader({ commit }, imageAsFile) {
    await commit('axiosUploadBegin');
    let data = new FormData();
    data.set('image', imageAsFile);   
    try {
      const query = await DataService.post('/image/upload', data, { 'Content-Type': 'multipart/form-data' });      
      commit('axiosUploadSuccess', `${query.data}`);
    } catch (err) {
      await commit('axiosUploadErr', err);
    }

  },

  //////////////////////////////////////////////
  // REPORTING

  async bandiGetDatiApertura({ commit } ) {
     
    try {
      await commit('axiosReportAperturaBegin');      
        const query = await DataService.get(`/vuedataxs/report/apertura`);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));   
        await commit('axiosReportAperturaResponse', xresponse);
    } catch (err) {
      //console.log(err);
      await commit('axiosReportAperturaErr', err);
    }
  },
  
  async bandiGetDatiChiusura({ commit } ) {
    try {
      await commit('axiosReportChiusuraBegin');      
        const query = await DataService.get(`/vuedataxs/report/chiusura`);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));     
        await commit('axiosReportChiusuraResponse', xresponse);
    } catch (err) {
      //console.log(err);
      await commit('axiosReportChiusuraErr', err);
    }
  },
  



};



export default {
  namespaced: false,
  state,
  actions,
  mutations,
};