//import FeaturesRoutes from './FeaturesRoutes';
//import PageRoutes from './PageRoutes';
//import Components from './Components';
import Apps from './Applications';

const routes = [
  {
    path: 'home',
    name: 'home',
    //component: () => import(/* webpackChunkName: "Home" */ '@/view/apps/kanban/Index.vue'),
    component: () => import(/* webpackChunkName: "demoOne" */ '@/view/dashboard/DashboardOne.vue'),
  }, 
  {
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    //component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/apps/kanban/Index.vue'),
    children: [
           
      {
        path: '',
        name: 'home',
        //component: () => import(/* webpackChunkName: "Home" */ '@/view/crud/axios/View.vue'),
        component: () => import(/* webpackChunkName: "demoOne" */ '@/view/dashboard/DashboardOne.vue'),
      },
      /*
      {
        path: 'demo-one',
        name: 'demo-one',
        component: () => import(  '@/view/dashboard/DemoOne.vue'),
      } 
      */
    ],
  },
  {
    path: '/bandi',
    name: 'bandi',
    component: () => import(/* webpackChunkName: "axios" */ '@/view/crud/axios/Index.vue'),
    children: [
      {
        path: 'view',
        name: 'bandi-view',
        //component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/axios/View.vue'),
        component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/axios/List.vue'),
      },
      {
        path: 'list',
        name: 'bandi-list',
        component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/axios/List.vue'),
      },
      {
        path: 'edit/:idx',
        name: 'bandi-edit',
        component: () => import(/* webpackChunkName: "axios-edit" */ '@/view/crud/axios/edit.vue'),
      },
      {
        path: 'add',
        name: 'bandi-add',
        component: () => import(/* webpackChunkName: "axios-add" */ '@/view/crud/axios/addNew.vue'),
      },
    ],
  },
  {
    path: '/contatti',
    name: 'contatti',
    component: () => import(/* webpackChunkName: "axios" */ '@/view/crud/contatti/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'contatti-list',
        component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/contatti/List.vue'),
      },
      {
        path: 'edit/:idx',
        name: 'contatti-edit',
        component: () => import(/* webpackChunkName: "axios-edit" */ '@/view/crud/contatti/edit.vue'),
      },
      {
        path: 'add',
        name: 'contatti-add',
        component: () => import(/* webpackChunkName: "axios-add" */ '@/view/crud/contatti/addNew.vue'),
      },
      {
        path: 'view/:idx',
        name: 'contatti-view',
        component: () => import(/* webpackChunkName: "axios-add" */ '@/view/crud/contatti/View.vue'),
      },
    ],
  },
  {
    path: '/documents',
    name: 'documenti',
    component: () => import(/* webpackChunkName: "axios" */ '@/view/crud/documents/Index.vue'),
    children: [
      {
        path: 'documents-list',
        name: 'documents-list',
        component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/documents/View.vue'),
      }
    ],
  },
  {
    path: '/activities',
    name: 'attivita',
    component: () => import(/* webpackChunkName: "axios" */ '@/view/crud/activities/Index.vue'),
    children: [
      {
        path: 'activities-list',
        name: 'activities-list',
        component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/activities/List.vue'),
      }
    ],
  },
  {
    path: '/utenti',
    name: 'utenti',
    component: () => import(/* webpackChunkName: "axios" */ '@/view/crud/utenti/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'utenti-list',
        component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/utenti/List.vue'),
      },
      {
        path: 'edit/:idx',
        name: 'utenti-edit',
        component: () => import(/* webpackChunkName: "axios-edit" */ '@/view/crud/utenti/edit.vue'),
      },
      {
        path: 'add',
        name: 'utenti-add',
        component: () => import(/* webpackChunkName: "axios-add" */ '@/view/crud/utenti/addNew.vue'),
      },
      {
        path: 'view/:idx',
        name: 'utenti-view',
        component: () => import(/* webpackChunkName: "axios-add" */ '@/view/crud/utenti/View.vue'),
      },
    ],
  },
  {
    path: '/page/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
  },
  {
    path: '/page/comingSoon',
    name: 'comingSoon',
    component: () => import(/* webpackChunkName: "comingSoon" */ '@/view/pages/ComingSoon.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
  },
  //...PageRoutes,
  //...FeaturesRoutes,
  //...Components,
  ...Apps,
];

export default routes;
