export default {
  axiosAddBeginBegin(state) {
    state.loading = true;
  },

  axiosAddBeginSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosAddBegin(state) {
    state.loading = true;
    state.success = false;
  },

  axiosAddSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.success = true;
  },

  axiosAddErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosReadBegin(state) {
    state.loading = true;
  },

  axiosReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosUpdateBegin(state) {
    state.loading = true;
  },

  axiosUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosDeleteBegin(state) {
    state.loading = true;
  },

  axiosDeleteSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosSingleDataBegin(state) {
    state.loading = true;
  },

  axiosSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
  },

  axiosUploadBegin(state) {
    state.fileLoading = true;
  },

  axiosUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  axiosUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  axiosSearchBegin(state) {
    state.loading = true;
  },

  axiosSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  axiosSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  /////////////////////////////////////////
  //////////     Document     /////////////
  /////////////////////////////////////////
  axiosDocumentBegin(state) {
    state.docsloading = true;
    state.docsreloading = false;
  },
  axiosDocumentSuccess(state, data) {
    state.docsloading = false;
    state.docsdata = data;
  },
  axiosDocumentErr(state, err) {
    state.docsloading = false;
    state.docserror = err;
    state.docsreloading = true;
  },
  axiosDocumentResponse(state, data) {
    state.docsreloading = false;
    state.docsloading = false;
    const docsresponse = data.map((s) => JSON.parse(s));
    state.docssuccess = docsresponse[0];
    state.docsresx = docsresponse[1];
    state.docsmessage = docsresponse[2];
    state.docsdata = docsresponse[3];
  },
  axiosDocumentStoreSuccess(state, data) {
    state.docsloading = false;
    state.docsdata = data.data;
    state.docsreloading = data.success;
  },
  axiosDocumentDeleteBegin(state) {
    state.docsloading = true;
    state.docsreloading = false;
  },
  axiosDocumentDeleteSuccess(state, data) {
    state.docsloading = false;
    state.docsdata = data;
    state.docsreloading = data.success;
  },
  axiosDocumentDeleteErr(state, err) {
    state.docsloading = false;
    state.docserror = err;
    state.docsreloading = true;
  },
  axiosDocumentDownloaded(state) {
    state.docsloading = false;
    state.docsreloading = true;
  },
  


  ///////////////////////////////
  ///////////////////////////////
  // // // FILTER 
  dataLiveFilterBegin(state) {
    state.docsloading = true;
    state.docsreloading = false;
  },
  dataLiveFilterSuccess(state, data) {
    state.docsloading = false;
    //state.docsdata = data;
    state.docslistdata = data;
  },
  dataLiveFilterErr(state, err) {
    state.docsloading = false;
    state.docserror = err;
    state.docsreloading = true;
  },
  ///////////////////////////////
  dataDocsLiveFilterBegin(state) {
    state.docslistloading = true;
  },
  dataDocsLiveFilterSuccess(state, data) {
    state.docslistloading = false;
    state.docslistdata = data;    
  },
  dataDocsLiveFilterErr(state, err) {
    state.docslistloading = false;
    state.docslisterror = err;
  },
  ///////////////////////////////
  axiosDocsListBegin(state) {
    state.docslistloading = true;   
  },
  dataDocsListSuccess(state, data) {
    state.docslistloading = false;
    state.docslistdata = data;
    state.docslistdataorig = data;
  },
  axiosDocsListResponse(state, data) {
    state.docslistloading = false;
    const docsresponse = data.map((s) => JSON.parse(s));
    state.docslistsuccess = docsresponse[0];
    state.docslistresx = docsresponse[1];
    state.docslistmessage = docsresponse[2];
    state.docslistdata = docsresponse[3];
    state.docslistdataorig = docsresponse[3];
  },
  axiosDocsListErr(state, err) {
    state.docslistloading = false;
    state.docslisterror = err;
  },
  //////////////////////////////////////////////
  // REPORTING

  axiosReportAperturaBegin(state) {
    state.dataReportloading = true;
  },
  axiosReportAperturaSuccess(state, data) {
    state.dataReportloading = false;
    state.dataApertura = data;
  },
  axiosReportAperturaErr(state, err) {
    state.dataReportloading = false;
    state.dataReporterror = err;
  },
  axiosReportAperturaResponse(state, data) {
    state.dataReportloading = false;
    const dataresponse = data.map((s) => JSON.parse(s));
    state.dataReportsuccess = dataresponse[0];
    state.dataReportresx = dataresponse[1];
    state.dataReportmessage = dataresponse[2];
    state.dataApertura = dataresponse[3];
  },


  axiosReportChiusuraBegin(state) {
    state.dataReportChiusuraloading = true;
  },
  axiosReportChiusuraSuccess(state, data) {
    state.dataReportChiusuraloading = false;
    state.dataChiusura = data;
  },
  axiosReportChiusuraErr(state, err) {
    state.dataReportChiusuraloading = false;
    state.dataReportChiusuraerror = err;
  },
  axiosReportChiusuraResponse(state, data) {
    state.dataReportChiusuraloading = false;
    const dataresponse = data.map((s) => JSON.parse(s));
    state.dataReportChiusurasuccess = dataresponse[0];
    state.dataReportChiusuraresx = dataresponse[1];
    state.dataReportChiusuramessage = dataresponse[2];
    state.dataChiusura = dataresponse[3];
  },
  

};