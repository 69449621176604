/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        "L'app viene servita dalla cache da un operatore del service worker.\n" +
        "Per maggiori dettagli, visitare https://goo.gl/AFskqB"
      )
    },
    registered () {
      console.log('Service worker è stato registrato.')
    },
    cached () {
      console.log("Il contenuto è stato memorizzato nella cache per l'utilizzo offline.")
    },
    updatefound () {
      console.log('È in corso il download di nuovi contenuti.')
    },
    updated () {
      console.log('Sono disponibili nuovi contenuti; per favore aggiorna.')
    },
    offline () {
      console.log("Nessuna connessione Internet trovata. L'app è in esecuzione in modalità offline.")
    },
    error (error) {
      console.error('Errore durante la registrazione del service worker:', error)
    }
  })
}
