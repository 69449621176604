import axios from 'axios';
import { getCookie } from './auth-header';

var CryptoJS = require("crypto-js");
 
//import notificationMessage from '@/config/notificationMessage/notificationMessage';

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const API_CRYPTOKEY = process.env.VUE_APP_CRYPTO_KEY36;
const API_CRYPTOPWD = process.env.VUE_APP_CRYPTO_PWD;
const API_IPADDRESS = process.env.VUE_APP_API_IPADDRESS;

const authHeader = () => ({
  'x-access-token': getCookie('access_token'),
  //Authorization: `Bearer ${getCookie('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'x-access-token': getCookie('access_token'),
    //Authorization: `Bearer ${getCookie('access_token')}`,
    'Content-Type': 'application/json',
  },
  withCredentials: true
});


/*

// // // mod token
const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: authHeader(),  // // // già inserito nei vari metodi
  withCredentials: true
})
*/


const IpSource = () => (
  axios.get(API_IPADDRESS)
    .then(response => {
      return response.ip;
    })
    .catch(error => {
      console.log ("IP error:", error);
      return "0.0.0.1"; //error;
    })
    .finally(() => {
      return "0.0.0.0";
    })

);

class DataService {

  /**
   * Decrypt an encrypted message
   * @param encryptedBase64 encrypted data in base64 format
   * @return The decrypted content
   */

  
  static activity_status_text = (value) => {

    const status_arr = ["","Not Started","Open","In Progress","Completed","Deferred","Canceled","Updated","Deleted"];
    return status_arr[value];
    
  }
  static encryptAES(text) {
    return CryptoJS.AES.encrypt(text, API_CRYPTOKEY).toString();
  }

  static decryptAES = (encryptedBase64) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, API_CRYPTOKEY);
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'error 1';
        }
      } catch (e) {
        return 'error 2';
      }
    }
    return 'error 3';
  }

  static encryptPwdAES(text) {
    return CryptoJS.AES.encrypt(text, API_CRYPTOPWD).toString();
  }

  static decryptPwdAES = (encryptedBase64) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, API_CRYPTOPWD);
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'error 1';
        }
      } catch (e) {
        return 'error 2';
      }
    }
    return 'error 3';
  }

  ////////////////////////////////  
  ////////////////////////////////  
  //////////////////////////////// 

  /*
 
  section = [
      "bandi",        0
      "contatti",     1
      "documents",    2
      "kanban",       3
      "note",         4
      "utenti",       5
      
      "task",         6
      "azioni",       7
      "activities",   8
      ...
      ...             15
  ];
  
  action = [
      "view" => 1, 
      "insert" => 2, 
      "update" => 4, 
      "delete" => 8
  ];

  */ 

  static checkAuthMenu = () => {
  
    const piniz = getCookie('piniz');
    const authRolesStr = this.decryptAES(piniz);
    const authRoles = JSON.parse(authRolesStr);
    
    let menuAuth = [];
    let section_roles = 0;

    for (let i = 0; i < 16 ; i++) {
     
      section_roles = authRoles[i];

      menuAuth[i] = {
        isView: (section_roles & 1) === 1 ? true : false,
        isInsert: (section_roles & 2) === 2 ? true : false,
        isUpdate: (section_roles & 4) === 4 ? true : false,
        isDelete: (section_roles & 8) === 8 ? true : false,
      }
    }

    return JSON.stringify(menuAuth);

  }

  static checkAuthSection = (_section) => {

    const section = typeof _section !== 'number' ? 0 : _section;
  
    const piniz = getCookie('piniz');
    const authRolesStr = this.decryptAES(piniz);
    const authRoles = JSON.parse(authRolesStr);
    
    let section_roles = authRoles[section];
   
    let isViewCheck = (section_roles & 1) === 1 ? true : false;
    let isInsertCheck = (section_roles & 2) === 2 ? true : false;
    let isUpdateCheck = (section_roles & 4) === 4 ? true : false;
    let isDeleteCheck = (section_roles & 8) === 8 ? true : false;

    let dataCheck = {
      isView: isViewCheck,
      isInsert: isInsertCheck,
      isUpdate: isUpdateCheck,
      isDelete: isDeleteCheck,
    }
   
    return JSON.stringify(dataCheck);

  }
  ////////////////////////////////  
  ////////////////////////////////  
  ////////////////////////////////  

 


  static isTokenResetHex(stringh) {

    let hexcheck = 0;
    let regexp = /^[0-9a-fA-F]+$/;

    let hexarray = stringh.split('.');

    if (hexarray.length !== 3) return false;

    for (let i = 0; i < hexarray.length; i++) {
      if (regexp.test(hexarray[i])) hexcheck++;
    }
    return (hexcheck === 3)
  }

  static getApiEndpoint() {
    return API_ENDPOINT;
  }

  static getIpSource() {
    return IpSource();
  }

  static getIpAddress() {
    axios.get(API_IPADDRESS)
      .then(response => {
        return response.data.ip;
      })
      .catch(error => {
        console.log("IP error:", error);
        return "-1";
        
      })
      .finally(() => {
        return "0.0.0.0";
      })
  }

  ////////////////////////////////  
  ////////////////////////////////  
  //////////////////////////////// 

  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */

axios.interceptors.request.use(
  (config) => {

    //const token = `Bearer ${getCookie('access_token')}`; 
    const token = getCookie('access_token');

    if (token) {
      //config.headers['Authorization'] = token;
      config.headers['x-access-token'] = token;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;

  //requestConfig.headers = { ...headers, Authorization: `Bearer ${getCookie('access_token')}` };
  requestConfig.headers = { ...headers, 'x-access-token': getCookie('access_token') };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
    * Do something in case the response returns an error code [3**, 4**, 5**] etc
    * For example, on token expiration retrieve a new access token, retry a failed request etc
    */

    //console.log('##');
    console.log('###');
    //console.log('client.interceptors.response error:  ', JSON.stringify(error));

    const { response } = error;
    //const originalRequest = error.config;
    if (response) {

      console.log(response.status);

      if (response.status === 500 || response.status === 413 || response.status === 404) {
        console.log('client.interceptors.response:  ',response.status);
        //notificationMessage.addNotificationRespx("KO",response.status);

      }
      
      if (response.status === 477) {
        console.log('client.interceptors.response status email già presente:  ',response.status );        
        //const err = { message: "email già presente", code: response.status }
        //return err;
      }
      
      if (response.status === 478) {
        console.log('client.interceptors.response inserire una email valida!:  ',response.status );  
        //notificationMessage.addNotificationRespx("KO","email non valida");     
        //const err = { message: "email non valida", code: response.status }
        //return err;
      }

      if (response.status === 403) {
        console.log(response.status);
        //localStorage.clear();
        //window.location.href = "/";
      }
  
      localStorage.clear();
      //window.location.href = "/";

      // reject with error if response status is not 403
      //return Promise.reject(error);
      //else {
      //  return originalRequest;
      //}
    }

    return Promise.reject(error);
  },
);
export { DataService };
