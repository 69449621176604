import { createRouter, createWebHistory } from 'vue-router'; // createWebHistory  createMemoryHistory
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';
// import VueCookies from 'vue-cookies';
//import Cookies from 'js-cookie';

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
    // // // mod token
    /*
    { 
      name: 'ErrorView',
      path: '/:pathMatch(.*)*', 
      //
      redirect: '/',
      //component: () => import('@/view/pages/404.vue') ,
      //
      meta: { auth: false },
    }
    */
    // // // 
  
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});


router.beforeEach((to, from, next) => {
  
  //console.log("auth.roles ", store.state.auth.roles);

  if (to.meta.auth && store.state.auth.login) {
    next({ to: '/' });
  } else if (!to.meta.auth && !store.state.auth.login) {
    next({ name: 'login' });
  } else {
    next();
  }
  window.scrollTo(0, 0); // reset scroll position to top of page
  next();
});
/*
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = VueCookies.get('logedIn') ; //localStorage.getItem('user');
  
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
*/

export default router;
