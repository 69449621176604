export default {
  loginBegin(state) {
    state.login = true;
    state.success = false;

  },
  
  loginSuccess(state, data, roles) {
    state.loading = false;
    state.login = data;
    state.success = true;
    state.data = data;
    state.roles = roles;
  },

  loginErr(state, err) {
    state.loading = false;
    state.error = err;

  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },

/////////////////
// RESET 
resetBegin(state,message) {
  state.loading = true;
  state.success = false;
  state.message = message;
},

resetSuccess(state, message) {
  state.loading = false;
  state.success = true;
  state.message = message;

},

resetMessage(state, message) {
  state.loading = false;
  state.success = false;
  state.message = message;

},

resetErr(state, message) {
  state.loading = false;
  state.success = false;
  state.message = message;
},

checkAuth() { 
},

};
