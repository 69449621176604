import { notification } from 'ant-design-vue';

export default {

    addNotificationRespx(respx, msg) {
        if (respx === "OK") this.addNotificationSuccess(msg);
        if (respx === "ALERT")  this.addNotificationWarning(msg);
        if (respx === "KO")  this.addNotificationError(msg);
    },

    addNotificationSuccess(msg) {
        notification.success({
            message: msg, //'Your Record has been Submited',
        });
    },

    addNotificationWarning(msg) {
        notification.warning({
            message: msg, //'alert',
        });
    },

    addNotificationError(err) {
        notification.error({
            message: err,
        });
    },

    deleteNotificationSuccess(msg) {
        notification.success({
            message: msg, //'Your Record has been Deleted',
        });
    },

    deleteNotificationError(err) {
        notification.error({
            message: err,
        });
    },

    updateNotificationSuccess(msg) {
        notification.success({
            message: msg, //'Your Record has been updated',
        });
    },

    updateNotificationError(err) {
        notification.error({
            message: err,
        });
    }

}