import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';
import { array } from 'vue-types';
//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';
 
const addNotificationRespx = (respx,msg) => {
  if (respx==="OK") addNotificationSuccess(msg);
  if (respx==="ALERT") addNotificationWarning(msg); 
  if (respx==="KO") addNotificationError(msg);   
};

const addNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been Submited',
  });
};

const addNotificationWarning = (msg) => {
  notification.warning({
    message: msg, //'alert',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = (msg) => {
  notification.success({
    message: msg, //'Your Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};


const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};



const state = () => ({
  idcontatto: 0,
  data: [],
  success: false,
  resx: null,
  message: null,
  rspn:[], 
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
  ///////////////////////////////
  dataorig: [],
  dataSearch: [],
  ///////////////////////////////
  bandoloading: false,
  bandodata: [],
  bandoerror: null,
  bandosuccess: false,
  bandoresx: null,
  bandomessage: null,
  ///////////////////////////////
  docsloading: false,
  docsreloading: false,
  docsdata: [],
  docserror: null,
  docssuccess: false,
  docsresx: null,
  docsmessage: null,
  ///////////////////////////////
  reportStatusData: [],
  reportStatusLoading: false,
  reportStatusError: null,
  reportStatusSuccess: false,
  reportStatusResx: null,
  reportStatusMessage: null,
  reportStatusRespData: [],

  reportCicloVitaData: [],
  reportCicloVitaLoading: false,
  reportCicloVitaError: null,
  reportCicloVitaSuccess: false,
  reportCicloVitaResx: null,
  reportCicloVitaMessage: null,
  reportCicloVitaRespData: [],
  
});

const actions = { 

  async contactGetDataSingle({ commit }, id) {
    try {
      await commit('contactReadBegin');
      const query = await DataService.get(`/contacts/${id}/show`);

      //console.log('query.data.message: ' + query.data.message);
      //respx[`message`] = query.data.message;
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message];
      const xresponse = respx.map(item => JSON.stringify(item))     
       
      
      await commit('contactReadSuccess', query.data.data);
      await commit('contactResponse', xresponse);

    } catch (err) {
      await commit('contactReadErr', err);
    }
  },

  async contactGetDataAll({ commit }) {
    try {
      await commit('contactReadBegin');
      const query = await DataService.get('/contacts/all');
      await commit('contactReadSuccess', query.data.data);
    } catch (err) {
      console.log(err);
      await commit('contactReadErr', err);
    }
  },

  async contactSubmitData({ commit }, data) {   
   
    action.action = "insert";
    action.oggetto = "contact";

    //await commit('contactAddBeginBegin');    
    await commit('contactAddBegin');    
    try {
      const response = await DataService.post('/contacts/store', data);
      addNotificationRespx(response.data.resx,response.data.message);
      //await commit('contactAddBeginSuccess', response.data.data);
      await commit('contactAddSuccess', response.data.data);
       

      action.oggetto_json = data; //JSON.stringify()
      //const datalog = 
      await DataService.post('/actions/store', action);
      
    } catch (err) {
      //await commit('contactAddBeginErr', err);
      await commit('contactAddErr', err);
    
      addNotificationError(err.message);
      
      action.evento = "error";      
      action.oggetto_json = err ;//JSON.stringify(err);
      action.descrizione = "errore inserimento dati";
      //const datalog = 
      await DataService.post('/actions/store', action);
      
    }
  },

  async contactUpdateData({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "contact";
    action.oggetto_json = data; //JSON.stringify(data);
     
    

    try {
      await commit('contactUpdateBegin');
      const response = await DataService.put(`/contacts/${id}/update`, data);
      commit('contactUpdateSuccess');
      updateNotificationSuccess(response.data.message);
      
      //const datalog = 
      await DataService.post('/actions/store', action);
    

    } 
    catch (err) {
      await commit('contactUpdateErr', err);
      updateNotificationError(err);

      action.oggetto = "errore";
      action.descrizione = err;
      //const datalog = 
      await DataService.post('/actions/store', action);

    }
  },
  
  async contactDeleteData({ commit }, { id }) {
  //async contactDeleteData({ commit }, { id, getData }) {
      try {
      await commit('contactDeleteBegin');
      const response = await DataService.delete(`/contacts/${id}/delete`, {});
      await commit('contactDeleteSuccess');
      //await getData();      
      deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('contactDeleteErr', err);
      console.log(err);
      deleteNotificationError(err);
    }
  },


  async contactSearchByKey({ commit }, {value, key, valueall, datax}) {
    try {
      
      commit('contactSearchBegin');      
      let datalist = JSON.parse(datax);
      const data = datalist.filter((item) => {
        if (value.toString() !== valueall.toString()) {
          //console.log('indi filter', item.indirizzi.filter(itemX => itemX[key] === value));
          return(item.indirizzi.filter(itemX => itemX[key] === value).length>0);                 
        }
        return datalist;
      });
      //console.log("<<<data" ,data);
      commit('contactSearchSuccess', data);
    } catch (err) {
      commit('contactSearchErr', err.toString());
    }
  },



  async contactSearchData({ commit }, searchItem) {
    try {      
      await commit('contactReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/contacts/search/${searchItem}`);
        await commit('contactReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/contacts/all');
          await commit('contactReadSuccess', query.data.data);
        } catch (err) {
           
          await commit('contactReadErr', err);
        }
      }
    } catch (err) {
       
      await commit('contactReadErr', err);
    }
  },

  async contactSearchStatus({ commit }, searchItem) {
    try {
      await commit('contactReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/contacts/status/${searchItem}`);
        await commit('contactReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/contacts/all');
          await commit('contactReadSuccess', query.data.data);
        } catch (err) {
           
          await commit('contactReadErr', err);
        }
      }
    } catch (err) {
       
      await commit('contactReadErr', err);
    }
  },

  async contactDataLog({ commit }, data ) {
    
    action.oggetto = data.oggetto;
    action.oggetto_json = JSON.stringify(data);
     

    await commit('contactAddBeginBegin');    
    try {
      //const datalog = 
      await DataService.post('/actions/store', action);
      //await commit('contactAddBeginSuccess', "inserimento dati");

    } catch (err) {
      await commit('contactAddBeginErr', err);
      addNotificationError(err.message);
      
      action.oggetto = "errore";            
      action.descrizione = err;
      //const datalog = 
      await DataService.post('/actions/store', action);
      
    }
    
  },

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////


  async contactDocsDataGet({ commit }, id) {
    try {
      await commit('contactDocumentBegin');
      const query = await DataService.get(`/documents/${id}/cnctfiles`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));               
      //console.log('xresponse', JSON.stringify(query.data.data));
      await commit('contactDocumentResponse', xresponse);
    } catch (err) {
      await commit('contactDocumentErr', err);
    }
  },

  async contactDocsDataDelete({ commit }, data) {   
     
    action.action = "delete";
    action.oggetto = data.xdata.nomeorig;
    action.oggetto_json = data.xdata; //JSON.stringify(data);
     
    action.data_action = new Date().toISOString();
    action.task_id = data.xdata.iddox; //data.reference_id;
    //action.taskOid = data.xdata.guid;

    //iddox: id,
    //reference: reference,
    //reference_id: reference_id,
    //nomefile: filename,
    //guid: uuid,
    //nomeorig: nome 

    await commit('contactDocumentDeleteBegin');    
    try {
      const response = await DataService.delete('/documents/delete', data);      
      addNotificationRespx(response.data.resx,response.data.message);      
      await commit('contactDocumentDeleteSuccess', response.data);
      action.evento = "success";      
      action.task = "documents"; //data.reference;
      action.descrizione = "cancellazione documento";
      action.note = "cancellazione documento id " + data.iddox + " - nome orig:" + data.nomeorig;

      await DataService.post('/actions/store', action);
      //const datalog = await DataService.post('/actions/store', action);

      // MOVE FILE TO TRASH 
      const responsemoved = await DataService.post('/documents/movetox', data);
      addNotificationRespx(responsemoved.data.resx,responsemoved.data.message);
      
      action.evento = responsemoved.evento;
      action.task = "filesystem"; //data.reference;
      action.descrizione = "Spostamento documento";
      action.note = responsemoved.description;

      await DataService.post('/actions/store', action);

    } catch (err) {
      await commit('contactDocumentDeleteErr', err);
      addNotificationError(err.message);
      
      action.evento = "error";
      action.oggetto = err.message; //JSON.stringify(err);      
      action.descrizione = "errore inserimento documento";

      //const datalog = 
      await DataService.post('/actions/store', action);

    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////

  async contactStoreDocument({ commit }, data) {   
       
    action.action = "insert";
    action.oggetto = data.oggetto;
    action.oggetto_json = data; //JSON.stringify(data);
     
    action.data_action = new Date().toISOString();

    await commit('contactDocumentBegin');    
    try {
      const response = await DataService.post('/documents/store', data);
      addNotificationRespx(response.data.resx,response.data.message);
      await commit('contactDocumentStoreSuccess', response.data);
      
      // cancello il file blob thumburl altrimenti errore nel memorizzare oggettoJson in action 
      response.data.data.thumburl = "";
      
      //let objjson = delete response.data.data[0].thumburl;
      //console.log("objjson: " + JSON.stringify(objjson));

      action.task = "documents"; //data.reference;
      action.task_id = response.data.data.id; //data.reference_id;
      //action.taskOid = data.reference_id;
      action.oggetto = data.nome;
      action.oggetto_json = response.data.data; //JSON.stringify(data);
      action.descrizione = "inserimento documento";
      action.note = "inserimento documento id " + response.data.data.id + " per il contatto id " + data.reference_id;

      await DataService.post('/actions/store', action);
      //const datalog = await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    } catch (err) {
      await commit('contactDocumentErr', err);
      addNotificationError(err.message);
      
      action.evento = "error";
      action.oggetto = err; //JSON.stringify(err);      
      action.oggetto_json = data; //JSON.stringify(data);
      action.descrizione = "errore inserimento documento";
      //const datalog = 
      await DataService.post('/actions/store', action);
    }
  },
  
  async contactBandiDataDelete({ commit }, data) {   
     
    action.action = "delete";
    action.oggetto = data.xdata.nomeorig;
    action.oggetto_json = data.xdata; //JSON.stringify(data);
     
    action.data_action = new Date().toISOString();
    action.task_id = data.xdata.iddox; //data.reference_id;
    //action.taskOid = data.xdata.guid;

    //iddox: id,
    //reference: reference,
    //reference_id: reference_id,
    //nomefile: filename,
    //guid: uuid,
    //nomeorig: nome 

    await commit('contactBandiDeleteBegin');    
    try {
      console.log(".");
    } catch (err) {
      console.log("..");
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  async contactBandiDataGet({ commit }, id) {
    try {
      await commit('contactDocumentBegin');
      const query = await DataService.get(`/documents/${id}/files`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));     
      await commit('contactDocumentResponse', xresponse);
    } catch (err) {
      await commit('contactDocumentErr', err);
    }
  },

  async contactBandiDataList({ commit }) { //status
    try {
      await commit('contactBandiListBegin');
      const query = await DataService.get(`/documents/all`);
      let respx = array();
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));         
      await commit('contactBandiListResponse', xresponse);
    } catch (err) {
      await commit('contactBandiListErr', err);
    }
  },

  async contactBandiSearch({ commit }, searchItem) {
    try {
      await commit('contactDocumentBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/documents/search/${searchItem}`);
        //await commit('contactReadSuccess', query.data.data);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));     
        await commit('contactDocumentResponse', xresponse);
      } else {
        try {
          const query = await DataService.get('/documents/all');
          //await commit('contactReadSuccess', query.data.data);
          let respx = array();
          respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
          const xresponse = respx.map(item => JSON.stringify(item));     
          await commit('contactDocumentResponse', xresponse);
        } catch (err) {
 
          await commit('contactDocumentErr', err);
        }
      }
    } catch (err) {
 
      await commit('contactDocumentErr', err);
    }
  },

  async dataLiveFilter ({commit}, {value, key, datax}) {    
    try {
      await commit('dataLiveFilterBegin');
      let datalist = JSON.parse(datax);
      let datafiltered = datalist.filter(item => item[key].toLowerCase().startsWith(value.toLowerCase()));
      
      /*
        let datafilter1 =  datalist.filter(
            (item) => item.id == itemx
        );
        let datafilter2 = datalist.filter(
            item => item[key].toLowerCase().startsWith(value.toLowerCase())
        );
        let datafilter3 = datalist.filter((item) => {
          return item.nome.startsWith(value);
        });
      */      
      await commit('dataLiveFilterSuccess', datafiltered);
    } catch (err) {
      await commit('dataLiveFilterErr', err);
    }    
  },

  async filterDataByKey({ commit }, {value, key, valueall, datax}) {
    try {      
      commit('dataLiveFilterBegin');
      let datalist = JSON.parse(datax);

      const data = datalist.filter((item) => {
        if (value.toString() !== valueall.toString()) {
          return item[key].toString() === value.toString();
          
        }
        return datalist;
      });
      commit('dataLiveFilterSuccess', data);
    } catch (err) {
      commit('dataLiveFilterErr', err.toString());
    }
  },

  async sortingDataByKey({ commit }, {sortBy, datax}) {
    try {
      commit('dataLiveFilterBegin');
      let datalist = JSON.parse(datax);
      const data = datalist.sort((a, b) => {
        return b[sortBy] - a[sortBy];
      });
      setTimeout(() => {
        commit('dataLiveFilterSuccess', data);
      }, 500);
    } catch (err) {
      commit('dataLiveFilterErr', err);
    }
  },


  async LiveFilter ({commit}, data) {    
    try {
      commit('LiveFilterBegin');
      commit('LiveFilterSuccess', data);
    } catch (err) {
      commit('LiveFilterErr', err);
    }    
  },

  ////////////////////////////////////////////////////////
  // // // REPORT // // // // // // // // // //

  async contactGetStatus({ commit } ) {
     
    try {
      await commit('contactReportStatusBegin');      
        const query = await DataService.get(`/contacts/report/status`);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));  
  
        await commit('contactReportStatusResponse', xresponse);
    } catch (err) {
      
      await commit('contactReportStatusErr', err);
    }
  },

  async contactGetCicloVita({ commit } ) {
     
    try {
      await commit('contactReportCicloVitaBegin');      
        const query = await DataService.get(`/contacts/report/ciclovita`);
        let respx = array();
        respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
        const xresponse = respx.map(item => JSON.stringify(item));   
        await commit('contactReportCicloVitaResponse', xresponse);
    } catch (err) {
      
      await commit('contactReportCicloVitaErr', err);
    }
  },

  



};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};