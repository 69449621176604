export default {
  
  toAddTaskBegin(state) {
    state.tsLoading = true;
  },

  toAddTaskSuccess(state, data) {
    state.tsLoading = false;
    state.taskData = data;
    state.data = data;
  },

  toAddTaskErr(state, err) {
    state.tsLoading = false;
    state.error = err;
  },

  toDeleteTaskBegin(state) {
    state.tsLoading = true;
  },

  toDeleteTaskSuccess(state, data) {
    state.tsLoading = false;
    state.taskData = data;
    state.data = data;
  },

  toDeleteTaskErr(state, err) {
    state.tsLoading = false;
    state.error = err;
  },

  ////////////////////////////////
  //////////////////////////////////////////
  // ADD state
  taskAddBegin(state) {
    state.loading = true;
    state.tsLoading = true;
    state.success = false;
  },

  taskAddSuccess(state, data) {
    state.loading = false;
    state.tsLoading = false;
    state.taskData = data;
    state.data = data;
    state.success = true;
  },

  taskAddErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
  },

  taskAddBeginBegin(state) {
    state.loading = true;
    state.tsLoading = true;
  },

  taskAddBeginSuccess(state, data) {
    state.loading = false;
    state.tsLoading = false;
    state.taskData = data;
    state.data = data;
  },

  taskAddBeginErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // READ state
  taskReadBegin(state) {
    state.loading = true;
    state.tsLoading = true;
  },

  taskReadSuccess(state, data) {
    state.loading = false;
    state.tsLoading = false;
    state.taskData = data;
    state.data = data;
  },

  taskReadErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // UPDATE state
  taskUpdateBegin(state) {
    state.loading = true;
    state.tsLoading = true;
    state.tsreloading = false;
  },

  taskUpdateSuccess(state) {
    state.loading = false;
    state.tsLoading = false;
    state.tsreloading = true;
    // state.data = data;
  },

  taskUpdateErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
    state.tsreloading = false;
  },

  //////////////////////////////////////////
  // DELETE state
  taskDeleteBegin(state) {
    state.loading = true;
    state.tsLoading = true;
    state.tsreloading = false;
  },

  taskDeleteSuccess(state) {
    state.loading = false;
    state.tsLoading = false;
    state.tsreloading = true;
    // state.data = data;
  },

  taskDeleteErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
    state.tsreloading = false;
  },


  //////////////////////////////////////////
  // RESPONSE 
  taskResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
  },

  //////////////////////////////////////////
  // SEARCH
  taskSearchBegin(state) {
    state.loading = true;
    state.tsLoading = true;
  },

  taskSearchSuccess(state, data) {
    state.loading = false;
    state.tsLoading = false;
    state.taskData = data;
    state.data = data;
  },

  taskSearchErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
  },

  //////////////////////////////////

  starUpdateBegin(state) {
    state.loading = true;
    state.tsLoading = true;
  },

  starUpdateSuccess(state, data) {
    state.loading = false;
    state.tsLoading = false;
    state.data = data;
  },

  starUpdateErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
  },

  completeUpdateBegin(state) {
    state.loading = true;
    state.tsLoading = true;
  },

  completeUpdateSuccess(state, data) {
    state.loading = false;
    state.tsLoading = false;
    state.data = data;
  },

  completeUpdateErr(state, err) {
    state.loading = false;
    state.tsLoading = false;
    state.error = err;
  },
};
