import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import notificationMessage from '@/config/notificationMessage/notificationMessage';
//import VueCookies from 'vue-cookies';
import Cookies from 'js-cookie';

const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};

const state = () => ({
  data: [],
  loading: false,
  error: null,
  success: false,
  resx: null,
  message: null,
});

const actions = {

  async noteGetDataAll({ commit }) {
    try {
      await commit('noteReadBegin');

      console.log('noteReadBegin');


      const query = await DataService.get('/notes/all');
      console.log(query.data.data);
      await commit('noteReadSuccess', query.data.data);
    } catch (err) {
      console.log(err);
      await commit('noteReadErr', err);
    }
  },

  async noteSubmitData({ commit }, data) {   
   
    action.action = "insert";
    action.oggetto = "note";

     
    await commit('noteAddBeginBegin');    
    try {
      const response = await DataService.post('/notes/store', data);
      
      notificationMessage.addNotificationRespx(response.data.resx,response.data.message);
      //await commit('noteAddBeginSuccess', response.data.data);
      await commit('noteAddSuccess', response.data.data);
      console.log("noteAddSuccess message: " + response.data.message);

      action.oggetto_json = data; //JSON.stringify()
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } catch (err) {
      let error = err;
      await commit('noteAddErr', err);
      if (typeof err === 'string')
      {    
        console.log(err);
        notificationMessage.addNotificationError(err);
      }
      else {error = JSON.stringify(err);}   

      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },

  async noteUpdateData({ commit }, { id, data }) {
    
    action.action = "update";
    action.oggetto = "update";
    action.oggetto_json = data; //JSON.stringify(data);
     
    

    try {
      await commit('noteUpdateBegin');
      const response = await DataService.put(`/notes/${id}/update`, data);
      commit('noteUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);
      
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    } 
    catch (err) {
      let error = err;
      await commit('noteUpdateErr', err);
      if (typeof err === 'string')
      {    
        console.log(err);
        notificationMessage.updateNotificationError(err);
      }
      else {error = JSON.stringify(err);}   

      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));

    }
  },
  
  async noteDeleteData({ commit }, { id, data }) { // { id, getData }
    
    action.action = "delete";
    action.oggetto = "delete";
    action.oggetto_json = data; //JSON.stringify(data);
     

    try {
      await commit('noteDeleteBegin');
      const response = await DataService.delete(`/notes/${id}/delete`, {});
      await commit('noteDeleteSuccess');
      //await getData();      
      notificationMessage.deleteNotificationSuccess(response.data.message);
      
      await DataService.post('/actions/store', action);

    } catch (err) {
      let error = err;
      await commit('noteDeleteErr', err);
      if (typeof err === 'string')
      {    
        console.log(err);
        notificationMessage.deleteNotificationError(err);
      }
      else {error = JSON.stringify(err);}   
           
      action.oggetto = "errore";
      action.descrizione = error;
      const datalog = await DataService.post('/actions/store', action);
      console.log("message: " + JSON.stringify(datalog));


    }
  },


  ////////////////////////////////////////////////////

  async onStarUpdate({ commit }, { data, id }) {
    try {
      commit('starUpdateBegin');
      data.map((item) => {
        if (item.key === id) {
          const fav = item;
          if (item.stared) {
            fav.stared = true;
          } else {
            fav.stared = false;
          }
        }
      });
      return commit('starUpdateSuccess', data);
    } catch (err) {
      commit('starUpdateErr', err);
    }
  },

  async onLabelUpdate({ commit }, { data, id, value }) {
    try {
      commit('labelUpdateBegin');
      data.map((item) => {
        if (item.key === id) {
          const fav = item;
          fav.label = value;
        }
        return commit('labelUpdateSuccess', data);
      });
    } catch (err) {
      commit('labelUpdateErr', err);
    }
  },

  async noteDragData({ commit }, data) {
    try {
      commit('labelUpdateBegin');
      commit('labelUpdateSuccess', data);
    } catch (err) {
      commit('labelUpdateErr', err);
    }
  },

  /*
  async onLabelFilter({ commit }, label) {
    try {
      commit('labelUpdateBegin');
      const data = initialState.filter((item) => {
        return label === 'all' ? initialState : label === 'favorite' ? item.stared : item.label === label;
      });

      commit('labelUpdateSuccess', data);
    } catch (err) {
      commit('labelUpdateErr', err);
    }
  },
  */


  ///////////////////////////////////////////////////

  async noteDraggable({ commit }, data) {
    try {
      commit('noteReadBegin');
      commit('noteReadSuccess', data);
    } catch (err) {
      commit('noteReadErr', err);
    }
  },
  

  
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
