export default {

  //////////////////////////////////////////
  // READ state
  utilityReadBegin(state) {
    state.loading = true;
  },

  utilityReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  utilityReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // RESPONSE 
  utilityResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
    state.data = xresponse[3];
    state.counter ++;
  },

  //////////////////////////////////////////
  // READ state
  comuniReadBegin(state) {
    state.loading = true;
  },

  comuniReadSuccess(state, data) {
    state.loading = false;
    state.comuni = data;
    //state.data = data;
  },

  comuniReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  //////////////////////////////////////////
  // RESPONSE 
  comuniResponse(state, data) {
    state.fileLoading = false;
    const xresponse = data.map((s) => JSON.parse(s));
    state.success = xresponse[0];
    state.resx = xresponse[1];
    state.message = xresponse[2];
  },

  
  
};
