export default {
  emailSendBegin(state) {
    state.emailLoading = true;
    state.emailSuccess = false;
  },

  emailSendSuccess(state, data) {
    state.emailLoading = false;
    state.emailData = data;
    state.emailSuccess = true;
  }, 

  emailSendErr(state, err) {
    state.emailLoading = false;
    state.emailError = err;
    state.emailSuccess = false;
  },


  singleEmailBegin(state) {
    state.loading = true;
  },

  singleEmailSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  singleEmailErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  ///////////////////////////////////
  templateReadBegin(state) {
    state.templateLoading = true;
    state.templateSuccess = false;
  },

  templateReadSuccess(state, data) {
    state.templateLoading = false;
    state.templateData = data;
    state.templateSuccess = true;
  }, 

  templateReadErr(state, err) {
    state.templateLoading = false;
    state.templateError = err;
    state.templateSuccess = false;
  },


  ///////////////////////////////////
  starUpdateBegin(state) {
    state.sLoading = true;
  },

  starUpdateSuccess(state, data) {
    state.sLoading = false;
    state.allMessage = data;
  },

  starUpdateErr(state, err) {
    state.sLoading = false;
    state.error = err;
  },
};
