export default [
  /*
  {
    name: 'kanban',
    path: '/app/kanban',
    component: () => import('../view/apps/kanban/Index.vue'),
  },
  */
  {
    name: 'task',
    path: '/app/task',
    component: () => import(/* webpackChunkName: "task" */ '../view/apps/task/Index.vue'),
    children: [
      {
        name: 'all',
        path: 'all',
        components: {
          default: () => import(/* webpackChunkName: "task" */ '../view/apps/task/Index.vue'),
          child: () => import(/* webpackChunkName: "all" */ '../view/apps/task/overview/all.vue'),
        },
      },
      {
        name: 'favorites',
        path: 'favorites',
        components: {
          default: () => import(/* webpackChunkName: "task" */ '../view/apps/task/Index.vue'),
          child: () => import(/* webpackChunkName: "favorites" */ '../view/apps/task/overview/favorites.vue'),
        },
      },
      {
        name: 'completed',
        path: 'completed',
        components: {
          default: () => import(/* webpackChunkName: "task" */ '../view/apps/task/Index.vue'),
          child: () => import(/* webpackChunkName: "completed" */ '../view/apps/task/overview/completed.vue'),
        },
      },
    ],
  },
  {
    name: 'users',
    path: '/app/users',
    component: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
    children: [
      {
        path: 'dataTable',
        name: 'dataTable',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/users/UserListDataTable.vue'),
        },
      },
      {
        path: 'team',
        name: 'team',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "team" */ '../view/apps/users/Team.vue'),
        },
      },
      {
        path: 'users',
        name: 'users-1',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "users-1" */ '../view/apps/users/Users.vue'),
        },
        children: [
          {
            path: 'user-grid',
            name: 'user-grid',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () => import(/* webpackChunkName: "user-grid" */ '../view/apps/users/overview/UserCard.vue'),
            },
          },
          {
            path: 'user-list',
            name: 'user-list',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () =>
                import(/* webpackChunkName: "user-list" */ '../view/apps/users/overview/UserCardList.vue'),
            },
          },
          {
            path: 'grid-style',
            name: 'grid-style',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () =>
                import(/* webpackChunkName: "user-style" */ '../view/apps/users/overview/UserCardStyle.vue'),
            },
          },
          {
            path: 'grid-group',
            name: 'grid-group',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () =>
                import(/* webpackChunkName: "user-group" */ '../view/apps/users/overview/UserCardGroup.vue'),
            },
          },
        ],
      },
      {
        path: 'add-user',
        name: 'add-user',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/users/AddUsers.vue'),
        },
        children: [
          {
            path: 'info',
            name: 'info',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/users/overview/Info.vue'),
            },
          },
          {
            path: 'work',
            name: 'work',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () => import(/* webpackChunkName: "work" */ '../view/apps/users/overview/work.vue'),
            },
          },
          {
            path: 'social',
            name: 'social-user',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () => import(/* webpackChunkName: "social-user" */ '../view/apps/users/overview/Social.vue'),
            },
          },
        ],
      },
    ],
  },
  {
    name: 'note',
    path: '/app/note',
    component: () => import(/* webpackChunkName: "note" */ '../view/apps/note/Note.vue'),
    children: [
      {
        path: 'all',
        name: 'note-all',
        components: {
          default: () => import(/* webpackChunkName: "note" */ '../view/apps/note/Note.vue'),
          child: () => import(/* webpackChunkName: "note-all" */ '../view/apps/note/overview/all.vue'),
        },
      },
      {
        path: 'favorite',
        name: 'note-favorite',
        components: {
          default: () => import(/* webpackChunkName: "note" */ '../view/apps/note/Note.vue'),
          child: () => import(/* webpackChunkName: "note-favorite" */ '../view/apps/note/overview/favorite.vue'),
        },
      },
      {
        path: 'personal',
        name: 'note-personal',
        components: {
          default: () => import(/* webpackChunkName: "note" */ '../view/apps/note/Note.vue'),
          child: () => import(/* webpackChunkName: "note-personal" */ '../view/apps/note/overview/personal.vue'),
        },
      },
      {
        path: 'work',
        name: 'note-work',
        components: {
          default: () => import(/* webpackChunkName: "note" */ '../view/apps/note/Note.vue'),
          child: () => import(/* webpackChunkName: "note-work" */ '../view/apps/note/overview/work.vue'),
        },
      },
      {
        path: 'social',
        name: 'social-note',
        components: {
          default: () => import(/* webpackChunkName: "note" */ '../view/apps/note/Note.vue'),
          child: () => import(/* webpackChunkName: "social-note" */ '../view/apps/note/overview/social.vue'),
        },
      },
      {
        path: 'important',
        name: 'note-important',
        components: {
          default: () => import(/* webpackChunkName: "note" */ '../view/apps/note/Note.vue'),
          child: () => import(/* webpackChunkName: "important" */ '../view/apps/note/overview/important.vue'),
        },
      },
    ],
  },
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'contact',
    path: '/app/contact',
    component: () => import(/* webpackChunkName: "contact-grid" */ '../view/apps/contact/Index.vue'),
    children: [
      {
        name: 'contact-grid',
        path: 'contact-grid',
        component: () => import(/* webpackChunkName: "contact-grid" */ '../view/apps/contact/ContactGrid.vue'),
      },
      {
        name: 'contact-list',
        path: 'contact-list',
        component: () => import(/* webpackChunkName: "contact-list" */ '../view/apps/contact/Contact.vue'),
      },
      {
        name: 'contact-create',
        path: 'contact-create',
        component: () => import(/* webpackChunkName: "contact-create" */ '../view/apps/contact/ContactCreate.vue'),
      },
    ],
  },
  {
    name: 'to-do',
    path: '/app/to-do',
    component: () => import(/* webpackChunkName: "to-do" */ '../view/apps/todo/Todo.vue'),
  },
  {
    name: 'import-export',
    path: '/app',
    component: () => import(/* webpackChunkName: "import" */ '../view/apps/importExport/Index.vue'),
    children: [
      {
        name: 'import',
        path: 'import',
        component: () => import(/* webpackChunkName: "import" */ '../view/apps/importExport/Import.vue'),
      },
      {
        name: 'export',
        path: 'export',
        component: () => import(/* webpackChunkName: "export" */ '../view/apps/importExport/Export.vue'),
      },
    ],
  },
  
];
