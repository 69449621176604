import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import notificationMessage from '@/config/notificationMessage/notificationMessage';
import Cookies from 'js-cookie';

const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};

const state = () => ({
  whatsappLoading: false,
  whatsappReloading: false,
  whatsappError: null,
  whatsappData: [],
  whatsappSuccess: false,
  whatsappResx: null,
  whatsappMessage: null,
  /////////////////////
  templateLoading: false,
  templateData: [],
  templateSuccess: false,
  templateResx: null,
  templateError: null,
});

const actions = {

  async whatsappSend({ commit }, data) {   // ToAddTask

    data.utente = Cookies.get('userid');
    data.nominativo = Cookies.get('nominativo');

    action.task = "activity"; 
    action.action = "send";
    action.oggetto = "invio messaggio whatsapp";
    //action.task_id = data.contact_id;

    await commit('whatsappSendBegin');

    try {
      //console.log("whatsappSend try data: " + JSON.stringify(data));
      let whatsappresponse = await DataService.post('/activities/sendwhatsappsingle', data);
      //console.log("whatsappresponse: " + JSON.stringify(whatsappresponse));
      notificationMessage.addNotificationRespx(whatsappresponse.data.resx, whatsappresponse.data.message);
      
      const messageId = whatsappresponse.data.data.task_id;

      action.oggetto_json = whatsappresponse;
      action.task_id = whatsappresponse.data.data.activity_id;
      action.descrizione = whatsappresponse.data.data.titolo;

//      console.log("whatsappSend try action: " + JSON.stringify(action));

      if (whatsappresponse.data.success) {
        await commit('whatsappSendSuccess', whatsappresponse);

        //let response = 
        await DataService.post('/actions/store', action);
        //notificationMessage.addNotificationRespx(response.data.resx, response.data.message);
        
      } else {

        await commit('whatsappSendErr', whatsappresponse.data.message);
        notificationMessage.addNotificationError(whatsappresponse.data.message);

        action.evento = "error";
        action.oggetto_json = whatsappresponse;//JSON.stringify(err);
        action.descrizione = "errore invio whatsapp";
        action.note = messageId;

        //const datalog =
        await DataService.post('/actions/store', action);
        //console.log("message: " + JSON.stringify(datalog));
      }

    } catch (err) {

      //await commit('taskAddBeginErr', err);
      await commit('whatsappSendErr', err);
      console.log(err);
      notificationMessage.addNotificationError(err.message);

      action.evento = "error";
      action.oggetto_json = data;
      action.descrizione = JSON.stringify(err); 
      action.note = "errore invio whatsapp - res1";
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }



  },

  async templateDataGet({ commit }) {
    
    try {
      await commit('templateReadBegin'); 
      const query = await DataService.get('/templates/all');
      //console.log("templateDataGet data: " + JSON.stringify(query));
      await commit('templateReadSuccess', query.data.data);
    } catch (err) {
      //console.log(err);
      await commit('templateReadErr', err);
    }
  },

  async templateDataSearch({ commit }, searchItem) {
    try {
      await commit('templateReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/templatates/search/${searchItem}`);
        await commit('templateReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/templatates/all');
          await commit('templateReadSuccess', query.data.data);
        } catch (err) {
          //console.log(err);
          await commit('templateReadErr', err);
        }
      }
    } catch (err) {
      //console.log(err);
      await commit('templateReadErr', err);
    }
  },

  
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
