import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import notificationMessage from '@/config/notificationMessage/notificationMessage';
import Cookies from 'js-cookie';
 

const activity = { 
  activitytype: "Email",
  evento: "success",
  task: "", // riferimento collection 
  task_id: "", // riferimento id
  titolo: "", // // // *******
  oggetto_json: "",
  descrizione: "", // // // *******
  note: "", // // // *******
  contactTo: "",
  contactFrom: "",
  dataInizio: "",
  dataFine: "",
  status: 4,
  location: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_activity: new Date().toISOString()
};

const action = {
  evento: "success",
  task: "",
  task_id: "0",
  action: "",
  oggetto: "",
  oggetto_json: "",
  descrizione: "",
  note: "",
  utente: Cookies.get('userid'),
  nominativo: Cookies.get('nominativo'),
  source_ip: Cookies.get('ipsocurce'),
  data_action: new Date().toISOString(),
  status: 1
};

const state = () => ({
  emailLoading: false,
  emailReloading: false,
  emailError: null,
  emailData: [],
  emailSuccess: false,
  emailResx: null,
  emailMessage: null,
  /////////////////////
  templateLoading: false,
  templateData: [],
  templateSuccess: false,
  templateResx: null,
  templateError: null,
});

const actions = {

  async emailSend({ commit }, data) {   // ToAddTask

    //console.log("********* emailSend data: " + JSON.stringify(data));   
    const _task_id = data.data.contact_id; //send_message_res.data.messages[0].id;
    const _data_act = new Date().toISOString();

    ///////////////////////////////
    activity.activitytype = "Email";
    activity.action = "send";
    activity.task = "contacts";  
    activity.task_id = _task_id; 
    activity.dataInizio = _data_act;
    activity.dataFine = _data_act;
    activity.oggetto = "Invio email a " + data.data.to;
    activity.descrizione = data.data.text;
    activity.data_action = _data_act;
    activity.contactTo = data.data.to; //
    activity.contactFrom = data.data.from;  
    ///////////////////////////////
    action.action = "insert";
    action.oggetto = "contact";
    ///////////////////////////////

    await commit('emailSendBegin');

    try {

      //console.log("emailSend try");
      // INVIA EMAIL CON EMAILSENDER
      let emailresponse = await DataService.post('/activities/sendemailsingle', data);
      notificationMessage.addNotificationRespx(emailresponse.data.resx, emailresponse.data.actionmsg);
      
      //console.log("********* emailresponse: " + JSON.stringify(emailresponse));   
      
      if (emailresponse.data.success) {
        //SE OK ALLORA ACTIVITY STORE
        await commit('emailSendSuccess', emailresponse);
        //console.log("emailSend message: " + emailresponse.data.actionmsg);
        

        activity.activity_id = emailresponse.data.activity_id;
        activity.contactFrom = emailresponse.data.from;
        activity.guid = emailresponse.data.guid
        activity.oggetto_json = emailresponse;
        activity.note = emailresponse.data.data.headers["x-message-id"];

        //console.log("********* activity: " + JSON.stringify(activity));   
        let response = await DataService.post('/activities/store', activity);
        notificationMessage.addNotificationRespx(response.data.resx, response.data.message);
        //await commit('taskAddBeginSuccess', response.data.data);
        //console.log("activities/store: " + JSON.stringify(response));



        action.oggetto_json = emailresponse; //JSON.stringify()
        //const datalog = 
        await DataService.post('/actions/store', action);
        //console.log("message: " + JSON.stringify(datalog));
      } else {

        await commit('emailSendErr', emailresponse.data.message);
        //console.log(emailresponse.data.message);
        notificationMessage.addNotificationError(emailresponse.data.message);

        action.evento = "error";
        action.oggetto_json = emailresponse;//JSON.stringify(err);
        action.descrizione = "errore invio email";

        //const datalog =
        await DataService.post('/actions/store', action);
        //console.log("message: " + JSON.stringify(datalog));
      }

    } catch (err) {

      //await commit('taskAddBeginErr', err);
      await commit('emailSendErr', err);
      //console.log(err);
      notificationMessage.addNotificationError(err.message);

      action.evento = "error";
      action.oggetto_json = err;//JSON.stringify(err);
      action.descrizione = "errore invio email - res1";
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }



  },

  async nodemailerSend({ commit }, data) {   // ToAddTask

    //console.log("emailSend ");

    //const text = data.text;
    const _task_id = data.contact_id; //send_message_res.data.messages[0].id;

    const _data_act = new Date().toISOString();
    activity.activitytype = "Email";
    activity.action = "send";
    activity.task = "contacts";  
    activity.task_id = _task_id; 
    activity.dataInizio = _data_act;
    activity.dataFine = _data_act;
    activity.titolo = "Invio email a " + data.emailprincipale,

    action.action = "insert";
    action.oggetto = "contact";

    await commit('emailSendBegin');

    try {

      //console.log("emailSend try");
      //console.log("data: " + JSON.stringify(data));
      // INVIA EMAIL CON EMAILSENDER
      let emailresponse = await DataService.post('/activities/sendemailsingle', data);
      notificationMessage.addNotificationRespx(emailresponse.data.resx, emailresponse.data.actionmsg);
      
      //console.log("emailresponse: " + JSON.stringify(emailresponse));
      


      if (emailresponse.data.success) {
        //SE OK ALLORA ACTIVITY STORE
        await commit('emailSendSuccess', emailresponse);
        //console.log("emailSend message: " + emailresponse.data.actionmsg);
        
        activity.oggetto_json = emailresponse;
        activity.note = emailresponse.data.data.messageId;
        let response = await DataService.post('/activities/store', activity);
        notificationMessage.addNotificationRespx(response.data.resx, response.data.message);
        //await commit('taskAddBeginSuccess', response.data.data);
        

        action.oggetto_json = emailresponse; //JSON.stringify()
        //const datalog = 
        await DataService.post('/actions/store', action);
        //console.log("message: " + JSON.stringify(datalog));
      } else {

        await commit('emailSendErr', emailresponse.data.message);
        //console.log(emailresponse.data.message);
        notificationMessage.addNotificationError(emailresponse.data.message);

        action.evento = "error";
        action.oggetto_json = emailresponse;//JSON.stringify(err);
        action.descrizione = "errore invio email";

        //const datalog =
        await DataService.post('/actions/store', action);
        //console.log("message: " + JSON.stringify(datalog));
      }

    } catch (err) {

      //await commit('taskAddBeginErr', err);
      await commit('emailSendErr', err);
      //console.log(err);
      notificationMessage.addNotificationError(err.message);

      action.evento = "error";
      action.oggetto_json = err;//JSON.stringify(err);
      action.descrizione = "errore invio email - res1";
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }



  },

  async taskUpdateData({ commit }, { id, data }) {

    action.action = "update";
    action.oggetto = "update";
    action.oggetto_json = data; //JSON.stringify(data);
     


    try {
      await commit('taskUpdateBegin');
      const response = await DataService.put(`/tasks/${id}/updatetd`, data);
      commit('taskUpdateSuccess');
      notificationMessage.updateNotificationSuccess(response.data.message);

      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
    catch (err) {
      let error = err;
      await commit('taskUpdateErr', err);
      if (typeof err === 'string') {
        //console.log("ok err=", err);
        notificationMessage.updateNotificationError(error);
      }
      else { error = JSON.stringify(err); }

      action.oggetto = "errore";
      action.descrizione = error;
      //const datalog = 
      await DataService.post('/actions/store', action);
      //console.log("message: " + JSON.stringify(datalog));

    }
  },

  async templateDataGet({ commit }) {
    
    try {
      await commit('templateReadBegin'); 
      const query = await DataService.get('/templates/all');
      //console.log("templateDataGet data: " + JSON.stringify(query));
      await commit('templateReadSuccess', query.data.data);
    } catch (err) {
      //console.log(err);
      await commit('templateReadErr', err);
    }
  },

  async templateDataSearch({ commit }, searchItem) {
    try {
      await commit('templateReadBegin');
      if (searchItem != '') {
        const query = await DataService.get(`/templatates/search/${searchItem}`);
        await commit('templateReadSuccess', query.data.data);
      } else {
        try {
          const query = await DataService.get('/templatates/all');
          await commit('templateReadSuccess', query.data.data);
        } catch (err) {
          //console.log(err);
          await commit('templateReadErr', err);
        }
      }
    } catch (err) {
      //console.log(err);
      await commit('templateReadErr', err);
    }
  },

  
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
