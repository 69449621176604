import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';

const state = () => ({
  data: [],
  comuni: [],
  loading: false,
  error: null,
  success: false,
  resx: null,
  message: null,
  counter: 0
});

const actions = {

  async comuniGetDataAll({ commit }) {
    try {
      await commit('comuniReadBegin');
      const query = await DataService.get('/utilities/comuni');
       
      await commit('comuniReadSuccess', query.data.data);
    } catch (err) {
      console.log(err);
      await commit('comuniReadErr', err);
    }
  },

  async checkPartitaIVA({ commit }, {item, _contact_id}) {
    
    console.log("async checkPartitaIVA: ", item, _contact_id); 

    try {
      
      await commit('utilityReadBegin');


      const query = await DataService.get('/contacts/checkpartitaiva/'+item+'/'+_contact_id);       
      let respx = [];
      respx = [query.data.success,query.data.resx,query.data.message,query.data.data];
      const xresponse = respx.map(item => JSON.stringify(item));     

      console.log("ok respx", JSON.stringify(respx));

      await commit('utilityResponse', xresponse);

      //const query = await DataService.get(`/utilities/checkpartitaiva/${searchItem}`);
      //await commit('utilityReadSuccess', query.data.data);

    } catch (err) {
      console.log(err);
      await commit('utilityReadErr', err);
    }
  }, 

  /*
  async onLabelFilter({ commit }, label) {
    try {
      commit('labelUpdateBegin');
      const data = initialState.filter((item) => {
        return label === 'all' ? initialState : label === 'favorite' ? item.stared : item.label === label;
      });

      commit('labelUpdateSuccess', data);
    } catch (err) {
      commit('labelUpdateErr', err);
    }
  }
  */ 

  
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
