export default {
  whatsappSendBegin(state) {
    state.whatsappLoading = true;
    state.whatsappSuccess = false;
  },

  whatsappSendSuccess(state, data) {
    state.whatsappLoading = false;
    state.whatsappData = data;
    state.whatsappSuccess = true;
  }, 

  whatsappSendErr(state, err) {
    state.whatsappLoading = false;
    state.whatsappError = err;
    state.whatsappSuccess = false;
  },


  singlewhatsappBegin(state) {
    state.loading = true;
  },

  singlewhatsappSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  singlewhatsappErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  ///////////////////////////////////
  templateReadBegin(state) {
    state.templateLoading = true;
    state.templateSuccess = false;
  },

  templateReadSuccess(state, data) {
    state.templateLoading = false;
    state.templateData = data;
    state.templateSuccess = true;
  }, 

  templateReadErr(state, err) {
    state.templateLoading = false;
    state.templateError = err;
    state.templateSuccess = false;
  },


  ///////////////////////////////////
  starUpdateBegin(state) {
    state.sLoading = true;
  },

  starUpdateSuccess(state, data) {
    state.sLoading = false;
    state.allMessage = data;
  },

  starUpdateErr(state, err) {
    state.sLoading = false;
    state.error = err;
  },
};
