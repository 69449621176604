import { createStore } from 'vuex';
import note from './modules/note/actionCreator';
import todo from './modules/todo/actionCreator';
import crud from './modules/crud/axios/actionCreator';
import contatti from './modules/contatti/actionCreator';
import utenti from './modules/utenti/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import KanbanBoard from './modules/kanban/actionCreator';
import themeLayout from './modules/themeLayout/actionCreator';
import chartContent from './modules/chartContent/actionCreator';
import task from './modules/task/actionCreator';
import utilities from './modules/utilities/actionCreator';
import headerSearchData from './modules/headerSearch/actionCreator';

import email from './modules/email/actionCreator';
import whatsapp from './modules/whatsapp/actionCreator';

import activities from './modules/activities/actionCreator';

////////////////////////////////////////////////////////////////////////
/*
import users from './modules/users';
import gallery from './modules/gallery';
import chat from './modules/chat/actionCreator';
import ecommerce from './modules/ecommerce/product/actionCreator';
import orders from './modules/ecommerce/orders/actionCreator';
import cart from './modules/ecommerce/cart/actionCreator';
import profile from './modules/profile/actionCreator';
import project from './modules/project/actionCreator';
import calendar from './modules/calendar/actionCreator';
import team from './modules/team/actionCreator';
import contact from './modules/contact/actionCreator';
import tickets from './modules/supportTickets/actionCreator';
import dataTable from './modules/data-filter/actionCreator';
import jobSearch from './modules/jobSearch/actionCreator';
*/
//import fireStore from './modules/crud/fireStore/actionCreator';
//import firebase from './modules/authentication/firebase/actionCreator';

export default createStore({
  modules: {
    note,
    todo,
    crud,
    contatti,
    utenti,
    auth,
    KanbanBoard,
    themeLayout,
    chartContent,
    task,
    utilities,
    headerSearchData,
    email,
    whatsapp,
    activities
    ////////////////////////////////
    /*
    users,
    gallery,
    profile,
    team,
    dataTable,
    project,
    calendar,
    email,
   
    chat,
    ecommerce,
    orders,
    cart,
    contact,
    tickets,
    jobSearch,
    */
    //fireStore,
    //firebase,
  },
});
